import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const UserProfile = props => {
	const { name, email, phone, url, id, img } = props;
	return (
		<ProfileContainer>
			<a
				data-testid="link"
				target="_blank"
				className="w-24 h-24 relative text-center"
				href={`/accounts/profile#${id}`}
				rel="noopener noreferrer"
			>
				{!img ? (
					<i style={{ fontSize: '26px' }} className="material-icons h-6 w-6 text-blue-600 hover:text-blue-400">
						account_circle
					</i>
				) : (
					<div
						className="h-6 w-6 rounded-full text-blue-600 hover:text-blue-400 text-sm bg-no-repeat bg-cover bg-center group relative"
						style={{ backgroundImage: `url("${img}")` }}
					/>
				)}
			</a>
			<div className="hidden">
				<div className="rounded transition bg-white shadow-lg z-20 border-t-2 border-blue-600 overflow-hidden ml-10">
					<div className="py-4 px-8">
						<p data-testid="name" className="text-lg text-grey-800 font-bold">
							{name}
						</p>
						<p data-testid="email" className="text-gray-700 text-sm">
							{email}
						</p>
						<p data-testid="phone" className="text-gray-700 text-sm">
							{phone}
						</p>
					</div>
					<div className="flex justify-between">
						<a
							data-testid="url"
							className="py-2 px-4 flex-1 text-sm bg-blue-600 text-white hover:bg-blue-700 transition font-bold text-center"
							target="_blank"
							rel="noopener noreferrer"
							href={url}
						>
							Visit Site
						</a>
						<a
							data-testid="id"
							className="py-2 px-4 flex-1 text-sm bg-blue-400 hover:bg-blue-500 text-white transition font-bold text-center"
							target="_blank"
							href={`/accounts/profile#${id}`}
							rel="noopener noreferrer"
						>
							View Profile
						</a>
					</div>
				</div>
			</div>
		</ProfileContainer>
	);
};

const ProfileContainer = styled.div`
	position: relative;
	text-align: center;
	line-height: 0;
	width: 25px;
	height: 25px;
	border-radius: 50%;

	.hidden {
		text-align: left;
		line-height: 1.5;
		z-index: 1;
	}
	&:hover {
		.hidden {
			display: block;
			position: absolute;
			top: 0px;
			left: 24px;
			z-index: 1;
		}
	}
`;

export default UserProfile;

UserProfile.propTypes = {
	name: PropTypes.string,
	email: PropTypes.string,
	phone: PropTypes.string,
	url: PropTypes.string.isRequired,
	id: PropTypes.any.isRequired,
	img: PropTypes.string,
};

UserProfile.defaultProps = {
	name: '',
	email: '',
	phone: '',
	img: '',
};
