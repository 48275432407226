import React, { useEffect } from "react";
import { useAuth0 } from "../hooks/useAuth0";

// eslint-disable-next-line
export default function PrivateRoute({ component: Component, path, ...rest }) {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({ appState: { targetUrl: path } });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  return <Component {...rest} />;
}
