import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";

type apiDataType = {
    email: string,
    tag: string,
    key: string,
    keapId?: string
}

export default class KeapForm extends Component {
  static propTypes = {
    getStuffFromTable: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      agileTag: "",
      isError: false,
      error: "",
      isSuccess: false,
      isShowing: false,
      isSummary: false,
    };
    this.clearForm = this.clearForm.bind(this);
    this.fakeSubmit = this.fakeSubmit.bind(this);
    this.tagEmUp = this.tagEmUp.bind(this);
    this.changeTxt = this.changeTxt.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: Boolean(value) });
  };

  fakeSubmit = (e) => {
    e.preventDefault();
  };

  clearForm = (e) => {
    e.preventDefault();
    this.setState({ isSummary: false, isShowing: false, agileTag: "" });
  };

  changeTxt(e) {
    const { value, id } = e.target;
    this.setState({ [id]: value });
  }

  async tagEmUp(e) {
    e.preventDefault();
    this.setState({
      isError: false,
      error: "",
      isShowing: false,
      isSummary: false,
    });

    const { agileTag } = this.state;
    if (agileTag === "") {
      this.setState({ isError: true, error: "Tag Text Is Required." });
      return;
    }

    const { getStuffFromTable } = this.props;
    const stuff = getStuffFromTable();

    await Promise.all(
      stuff.map(async (item) => {
        const email = item.email.toLowerCase();
        console.warn(`adding ${agileTag} tag to ${email}`);
        console.log('item', item);

        const data:apiDataType = {
            email,
            tag: agileTag,
            key: "jWQ2~8Wm$HM,DZ~J"
        };

        if(typeof item.keap_id !== 'undefined' && Number.isInteger(item.keap_id)){
            data.keapId = "" + item.keap_id
        }
        return axios.post("https://go.eapdev.com/add-contact-to-sqs", data);
      })
    );
    this.setState({ isSuccess: true, agileTag: "" });
    setTimeout(() => this.setState({ isSuccess: false }), 2000);
  }

  render() {
    const {
      agileTag,
      isError,
      error,
      isSuccess,
      isShowing,
      isSummary,
    } = this.state;
    return (
      <div className="w-full">
        <div>
          <form
            onSubmit={this.fakeSubmit}
            className="flex rounded m-0 items-center w-full flex-wrap "
          >
            {!isSummary && (
              <>
                <div
                  style={{ backgroundImage: 'url("./logo_keap.webp")' }}
                  className="w-full h-12 bg-no-repeat bg-contain bg-center mb-2"
                />
                <input
                  placeholder="Add an Keap tag..."
                  className="border rounded rounded-r-none outline-none  hover:border-gray-400 focus:border-blue-500 focus:bg-white py-2 px-4 flex-1"
                  type="text"
                  id="agileTag"
                  value={agileTag}
                  onChange={this.changeTxt}
                />

                <button
                  disabled={agileTag.length < 3}
                  name="isSummary"
                  value={isSummary}
                  onClick={this.handleToggle}
                  type="button"
                  style={{ borderRadius: "0 4px 4px 0" }}
                  className="button rounded-l-none cursor-pointer"
                >
                  Add Tag
                </button>
              </>
            )}
            {isSummary && (
              <div>
                <div className="text-base font-bold text-gray-900 block mr-4">
                  Apply Agile tag{" "}
                  <span className="bg-blue-500 rounded px-4 py-1 shadow border-2 border-white text-white">
                    {agileTag}
                  </span>{" "}
                  to ALL currently visible users?
                </div>
                <button
                  onClick={this.tagEmUp}
                  className="button rounded-l-none block cursor-pointer w-full mt-4"
                  type="button"
                >
                  Apply Changes
                </button>
              </div>
            )}
          </form>
          {isSummary && (
            <>
              <span className="items-center ml-4 mr-4 pt-4 block text-right">
                <a href="#" className="text-right" onClick={this.clearForm}>
                  Cancel
                </a>
              </span>
              <span className="text-base text-gray-600 block  text-center pt-4">
                Be careful, this cannot be undone.
              </span>
            </>
          )}
        </div>
      </div>
    );
  }
}
