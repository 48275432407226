import React from "react";
import { Router } from "@reach/router";
import { toast } from "react-toastify";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider } from "@apollo/react-hooks";
import Layout from "../components/Layout";
import Home from "../components/Home";
import PrivateRoute from "../components/PrivateRoute";
import Profile from "../components/Profile/Profile";
import routes from "../Routes";
import isBrowser from "../Auth/isBrowser";
import HomePageReport from "../components/Reports/HomePageReport";
import StatesReport from "../components/Reports/StatesReport";
import DelinquentReport from "../components/Reports/DelinquentReport";
import IdxRoster from "../components/Reports/IdxRoster";
import CrmReport from "../components/Reports/Crms/CrmPageReport";
import BuildPage from "../components/Build/BuildPage";
import EventPage from "../components/Events/EventPage";
import Search from "../components/Search";
import { useAuth0 } from "../hooks/useAuth0";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/MainLoader";
import DeployLeadSites from "../components/DeployLeadSites";

toast.configure({ autoClose: 8000, draggable: false });

function App() {
  if (!isBrowser) {
    return "";
  }

  const { loading, getIdTokenClaims } = useAuth0();
  window.getToken = getIdTokenClaims;

  if (loading || !getIdTokenClaims) return <Loader />;

  const httpLink = createHttpLink({ uri: "https://w1g9f5qkfc.execute-api.us-east-1.amazonaws.com/dev/graphql" });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getIdTokenClaims();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <Layout>
        <Router>
          <PrivateRoute path={routes.APP} component={Home} />
          <PrivateRoute path={routes.PROFILE} component={Profile} />
          <PrivateRoute path={routes.SEARCH} component={Search} />
          <PrivateRoute path={routes.HOMEPAGE} component={HomePageReport} />
          <PrivateRoute path={routes.CRM} component={CrmReport} />
          <PrivateRoute path={routes.STATES} component={StatesReport} />
          <PrivateRoute path={routes.DELINQUENT} component={DelinquentReport} />
          <PrivateRoute path={routes.BUILD} component={BuildPage} />
          <PrivateRoute path={routes.DEPLOY} component={DeployLeadSites} />
          <PrivateRoute path={routes.EVENTS} component={EventPage} />
          <PrivateRoute path={routes.IDX_ROSTER} component={IdxRoster} />
        </Router>
      </Layout>
    </ApolloProvider>
  );
}

export default App;
