import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LogoCard = props => {
    const {link, name, image, isAvailable} = props;
    let {backgroundSize} = props;
    backgroundSize = typeof backgroundSize === 'undefined' ? 155 : backgroundSize;

    return (
        <LogoCardContainer
            data-testid="card"
            href={isAvailable ? link : '#'}
            target="_blank"
            style={{
                backgroundImage: `url("${image}")`,
                backgroundPosition: 'center 40%',
                backgroundSize: `${backgroundSize}px`
            }}
            className={
                isAvailable
                    ? 'available component__logoCard text-center bg-white hover:shadow-lg md:w-1/3 p-4 border-2 border-gray-100 hover:border-blue-500 hover:z-20 bg-no-repeat pt-12'
                    : 'notavailable component__logoCard text-center bg-white w-1/3 p-4 border-2 border-gray-100 bg-no-repeat pt-12'
            }
        >
            <p data-testid="name" className="text-base block mt-10 pt-10 text-blue-500">
                View {name} →
            </p>
        </LogoCardContainer>
    );
};

const LogoCardContainer = styled.a`
    position: relative;
    filter: grayscale(110%);
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
    &.available {
        filter: grayscale(0%);
        opacity: 1;
        cursor: pointer;
        pointer-events: all;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;
export default LogoCard;

LogoCard.propTypes = {
    name: PropTypes.string.isRequired,
    link: PropTypes.any.isRequired,
    image: PropTypes.string.isRequired,
    isAvailable: PropTypes.bool
};

LogoCard.defaultProps = {
    isAvailable: false
};
