import axios from "axios";
import qs from "qs";
import cleanObject from "../helpers/cleanObject";

let hasRetried = false;

const axiosAdd401Interceptor = (axiosRequest) => {
  axiosRequest.interceptors.response.use(null, async (error) => {
    console.log("Hit errors", { hasRetried });

    if (hasRetried) {
      console.log("Navigate home?");
      //   navigate(routes.HOME);
      return false;
    }

    hasRetried = true;
    const { config } = error;
    const { __raw: token } = await window.getToken();
    config.headers.Authorization = `Bearer ${token}`;
    return axiosRequest.request(config);
  });
};

export default {
  get: async (url, params = {}) => {
    const { __raw: token } = await window.getToken();
    const queryString =
      Object.keys(params).length > 0 ? `?${qs.stringify(params)}` : "";
    const args = {
      url: `${process.env.GATSBY_SERVER}${url}${queryString}`,
      method: "get",
      contentType: "application/json",
      headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
    };

    const mainCall = axios.create(args);

    // axiosAdd401Interceptor(mainCall);

    try {
      const res = await mainCall.request();
      return res.data;
    } catch (err) {
      return err;
    }
  },
  delete: async (url, params = {}) => {
    const { __raw: token } = await window.getToken();
    const mainCall = axios.create({
      url: `${process.env.GATSBY_SERVER}${url}`,
      method: "delete",
      contentType: "application/json",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: cleanObject(params),
    });

    axiosAdd401Interceptor(mainCall);

    try {
      const res = await mainCall.request();
      return res.data;
    } catch (err) {
      return err;
    }
  },
  post: async (url, params = {}) => {
    const data = cleanObject(params);
    const { __raw: token } = await window.getToken();

    try {
      const res = axios.post(`${process.env.GATSBY_SERVER}${url}`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (err) {
      console.error(err);
      return err;
    }
  },
};
