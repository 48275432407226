export default {
	AB: 'BG',
	Auckland: 'BG',
	'Île-de-France': 'BG',
	'Bay Of Plenty': 'BG',
	BC: 'BG',
	GP: 'BG',
	NM: 'BG',
	NSW: 'BG',
	NV: 'BG',
	ON: 'BG',
	SA: 'BG',
	UT: 'BG',
	TX: 'BG',
	AK: 'CM',
	AR: 'CM',
	CT: 'CM',
	DE: 'CM',
	IA: 'CM',
	ID: 'CM',
	IL: 'CM',
	IN: 'CM',
	KS: 'CM',
	KY: 'CM',
	MA: 'CM',
	MD: 'CM',
	ME: 'CM',
	MI: 'CM',
	MN: 'CM',
	MO: 'CM',
	MT: 'CM',
	NE: 'CM',
	NH: 'CM',
	NJ: 'CM',
	OH: 'CM',
	OK: 'CM',
	OR: 'CM',
	PA: 'CM',
	RI: 'CM',
	WI: 'CM',
	WV: 'CM',
	AZ: 'EA',
	CA: 'EA',
	CO: 'EA',
	HI: 'EA',
	WA: 'EA',
	NY: 'JC',
	AL: 'SW',
	DC: 'SW',
	FL: 'SW',
	GA: 'SW',
	LA: 'SW',
	MS: 'SW',
	NC: 'SW',
	SC: 'SW',
	TN: 'SW',
	VA: 'SW',
};
