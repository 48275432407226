import React from "react";

export default function StripeLink(props) {
	const { value } = props;
	if (!value) {
		return (
			<span className="text-red-500">● Error</span>
		)
	}
	return (
		<a
			className="text-blue-500"
			target="blank"
			rel="noopener noreferrer"
			href={`https://dashboard.stripe.com/customers/${value}`}
		>
			Stripe →
		</a>
	);
}
