import React from 'react';
import PropTypes from 'prop-types';

export default function CloudflareId(props) {
	const { domain } = props;
	return (
		<a href={`https://dash.cloudflare.com/d8f1ec0f1ee839b02bff2df2eae137f1/${domain}`} target="_blank" rel="noopener noreferrer">
			Cloudflare
		</a>
	);
}

CloudflareId.propTypes = {
	domain: PropTypes.string,
};
CloudflareId.defaultProps = {
	domain: '#',
};
