import React from "react";
import styled from "styled-components";

const Loader = () => {
    return (
        <LoaderContainer>
            <div className="ninja-loader" id="loading">
                <div className="spinner-container" />
                <div className="ninja" />
                <p className="text-center text-base text-gray-800 mt-4">
                    Loading...
                </p>
            </div>
        </LoaderContainer>
    );
};

const LoaderContainer = styled.div`
    display: block;
    margin: 0 auto;
    @-webkit-keyframes spin-load {
        to {
            -webkit-transform: rotate(360deg);
        }
    }
    .ninja-loader {
        position: relative;
        width: 100px;
        height: 100px;
        margin: 120px auto;
    }
    .ninja-loader .spinner-container {
        border-radius: 100%;
        margin: 0px auto;
        display: block;
        width: 100px;
        height: 100px;
        border: 3px solid #90cdf4;
        border-radius: 50%;
        border-top-color: #fff;
        animation: spin-load 1s ease-in-out infinite;
        -webkit-animation: spin-load 1s ease-in-out infinite;
    }
    .ninja {
        background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1642060/eap-ninja.png");
        background-size: 90%;
        background-repeat: no-repeat;
        background-position: center;
        height: 100px;
        width: 100px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    @-webkit-keyframes spin-load {
        to {
            -webkit-transform: rotate(360deg);
        }
    }
    @keyframes spin-load {
        to {
            -webkit-transform: rotate(360deg);
        }
    }
`;
export default Loader;
