import React from 'react';
import PropTypes from 'prop-types';

const ReportsContainer = props => {
	const { title, subtitle, upper, lower } = props;
	return (
		<div>
			<div className="container m-auto">
				<div className="py-12">
					<h2 data-testid="title" className="text-xl text-gray-900 mb-2">
						Report: {title}
					</h2>
					<p data-testid="subtitle" className="text-base text-gray-700">
						{subtitle}
					</p>
				</div>
			</div>
			<div className="bg-gray-100 h-full pb-64">
				<div className="bg-white p-12 mb-6 text-white border-b">
					<div data-testid="upper" className="container m-auto">
						{upper}
					</div>
				</div>
				<div data-testid="lower" className="container m-auto">
					{lower}
				</div>
			</div>
		</div>
	);
};

export default ReportsContainer;

ReportsContainer.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	upper: PropTypes.node,
	lower: PropTypes.node
};
ReportsContainer.defaultProps = {
	upper: '',
	lower: ''
};
