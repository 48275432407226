import React from 'react';
import PropTypes from 'prop-types';

export default function MinMaxSelect(props) {
	const { onChange, filter } = props;
	const { min, max } = filter.value;

	return (
		<div className="flex flex-wrap">
			<input
				className="w-1/2"
				type="number"
				value={min}
				placeholder="min"
				onChange={event => onChange({ min: parseInt(event.target.value, 10), max })}
			/>
			<input
				className="w-1/2"
				type="number"
				value={max}
				placeholder="max"
				onChange={event => {
					onChange({ min, max: parseInt(event.target.value, 10) });
				}}
			/>
		</div>
	);
}

MinMaxSelect.propTypes = {
	onChange: PropTypes.func.isRequired,
	filter: PropTypes.object,
};

MinMaxSelect.defaultProps = {
	filter: { value: { min: 0, max: '' } },
};
