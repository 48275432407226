import React from 'react';
import toUrl from '../../helpers/toUrl';

export default function Domain(props) {
	const { url } = props;
	return (
		<a target="blank" rel="noopener noreferrer" href={toUrl(url)}>
			{url}
		</a>
	);
}
