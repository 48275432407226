const booleanFilter = (filter, row) => {
	if (filter.value === 'all') {
		return true;
	}
	if (filter.value === 'true') {
		return row[filter.id];
	}

	return !row[filter.id];
};

export default booleanFilter;
