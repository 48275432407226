import React from 'react';
import PropTypes from 'prop-types';

export default function AgileId(props) {
	const { ID } = props;
	return (
		<a href={`https://easyagentp.agilecrm.com/#contact/${ID}`} target="_blank" rel="noopener noreferrer">
			Agile
		</a>
	);
}

AgileId.propTypes = {
	ID: PropTypes.number.isRequired,
};
