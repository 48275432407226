import React from 'react';
import PropTypes from 'prop-types';

export default function DelinquentSelect(props) {
	const { onChange, filter } = props;
	return (
		<select onChange={event => onChange(event.target.value)} style={{ width: '100%' }} value={filter ? filter.value : 'all'}>
			<option value="all">All</option>
			<option value="paid">Paid</option>
			<option value="hold">$25 hold</option>
			<option value="delinquent">Delinquent</option>
			<option value="cancelled">Cancelled</option>
		</select>
	);
}

DelinquentSelect.propTypes = {
	onChange: PropTypes.func.isRequired,
	filter: PropTypes.any.isRequired,
};
