const downloadCsv = ({ csvString, filename }) => {
	const blob = new Blob([csvString]);
	if (window.navigator.msSaveOrOpenBlob)
		// IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
		window.navigator.msSaveBlob(blob, 'filename.csv');
	else {
		const a = window.document.createElement('a');
		a.href = window.URL.createObjectURL(blob, { type: 'text/plain' });
		a.download = `${filename}.csv`;
		window.document.body.appendChild(a);
		a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
		window.document.body.removeChild(a);
	}
};
export default downloadCsv;
