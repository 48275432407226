import React from 'react';
import PropTypes from 'prop-types';

export default function RangeSelect(props) {
	const { onChange, filter, options } = props;
	return (
		<select onChange={event => onChange(event.target.value)} style={{ width: '100%' }} value={filter ? filter.value : 'all'}>
			<option value="all">All</option>
			{options.map(opt => (
				<option key={opt} value={opt}>{`${opt}+`}</option>
			))}
		</select>
	);
}

RangeSelect.propTypes = {
	onChange: PropTypes.func.isRequired,
	filter: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
	options: PropTypes.arrayOf(PropTypes.number.isRequired),
};

RangeSelect.defaultProps = {
	filter: 'all',
	options: [5, 10, 20, 50, 100],
};
