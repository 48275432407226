import React from 'react';
import PropTypes from 'prop-types';
import states from './states.json';

const StateSelect = props => {
	const { onChange, filter } = props;

	const statesArray = Object.keys(states);

	const StateOptions = () =>
		statesArray.map(state => (
			<option data-testid="states" key={state} value={state}>
				{state}
			</option>
		));

	return (
		<select data-testid="select" onChange={event => onChange(event.target.value)} className="w-full" value={filter.value}>
			<option value="all">All</option>
			<StateOptions />
		</select>
	);
};

StateSelect.propTypes = {
	onChange: PropTypes.func.isRequired,
	filter: PropTypes.object
};

StateSelect.defaultProps = {
	filter: { value: 'all' }
};

export default StateSelect;
