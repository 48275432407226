import React, { useState } from "react";
import AgileForm from "./AgileForm";
import KeapForm from "./KeapForm";
import ZendeskTagSelector from "./ZendeskTagSelector";

enum TagTarget {
    AGILE = 'AGILE',
    KEAP = 'KEAP',
    ZENDESK = 'ZENDESK',
}

const AddATag = ({ data }: {
    data():any
}) => {
    const {AGILE, KEAP, ZENDESK} = TagTarget;
    const [isShowing, setIsShowing] = useState(false);
    const [selectedTarget, setSelectedTarged] = useState<TagTarget>(KEAP);

    if (!isShowing) {
        return (
            <button
                className="button"
                onClick={()=>setIsShowing(true)}
                type="button"
            >
                <i className="material-icons">local_offer</i>Add a tag
            </button>
        );
    }
    return (
        <div
            style={{ width: 400 }}
            className="shadow-lg p-8 rounded border absolute z-20 bg-white right-0"
        >
            <h2>Need to add a tag?</h2>
            <button
                type="button"
                className="absolute right-0 top-0 p-4"
                onClick={() => setIsShowing(false)}
            >
                <i className="material-icons text-gray-600">close</i>
            </button>
            <div className="flex pt-8">
                {/* <button
                    onClick={() => setSelectedTarged(AGILE)}
                    style={{ borderRadius: "4px 0 0 4px" }}
                    type="button"
                    className={`button flex-1 ${
                        selectedTarget !== AGILE ? "--is-light" : "--is-blue"
                    }`}
                >
                    Agile
                </button> */}
                <button
                    onClick={() => setSelectedTarged(KEAP)}
                    style={{ borderRadius: "4px 0 0 4px" }}
                    type="button"
                    className={`button flex-1 ${
                        selectedTarget !== KEAP ? "--is-light" : "--is-blue"
                    }`}
                >
                    Keap
                </button>
                <button
                    onClick={() => setSelectedTarged(ZENDESK)}
                    style={{ borderRadius: "0 4px 4px 0" }}
                    type="button"
                    className={`button flex-1 ${
                        selectedTarget !== ZENDESK ? "--is-light" : "--is-blue"
                    }`}
                >
                    Zendesk
                </button>
            </div>
            <div className="flex pt-8">
                {{
                    // AGILE: <AgileForm getStuffFromTable={data} />,
                    KEAP: <KeapForm getStuffFromTable={data} />,
                    ZENDESK: <ZendeskTagSelector getStuffFromTable={data} />,
                }[selectedTarget]}
            </div>
        </div>
    );
};


export default AddATag;
