import React, { Component } from 'react';
import isEmpty from 'lodash.isempty';
import 'react-table/react-table.css';
import ReactTable from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import { PieChart } from 'react-chartkick';
import { Chart } from 'react-google-charts';
import CustomAxios from '../../data/axios';
import Loader from '../MainLoader';
import ReportContainer from '../Layout/ReportsContainer/ReportsContainer';
import whatPercent from '../../helpers/whatPercent';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export default class StatesReport extends Component {
	constructor() {
		super();
		this.state = {
			isLoading: true,
			states: []
		};
	}

	async componentDidMount() {
		try {
			const data = await CustomAxios.get('reports-states');
			if (!isEmpty(data.stateCount)) {
				this.setState({
					isLoading: false,
					states: data.stateCount
				});
			}
		} catch (err) {
			console.warn({ err });
		}
	}

	render() {
		const { isLoading, states } = this.state;
		if (isLoading) {
			return <Loader />;
		}

		console.warn({ states });
		// Google needs this format
		const formatedStates = states.map(state => ({ ISO: `US-${state.value}`, name: state.value, total: state.count }));
		formatedStates.unshift({ ISO: 'State', name: 'Name', total: 'Popularity' });
		console.warn({ formatedStates });
		// Formatted version for map
		const googleFormattedData = formatedStates.map(site => [site.ISO, site.total]);
		const tableFormattedData = formatedStates.filter(item => item.ISO.includes('US'));

		const totalArray = tableFormattedData.map(item => item.total);
		const reducer = (accumulator, currentValue) => accumulator + currentValue;
		// Total used for %
		const statesTotal = totalArray.reduce(reducer);
		// For PieChart
		const pieChartData = formatedStates.filter(data => data.total >= 25).map(item => [item.name, item.total]);

		const UpperContent = () => (
			<>
				<div className="flex justify-betwen flex-wrap">
					<div className="md:w-2/3 xs:w-full">
						<Chart
							chartType="GeoChart"
							width="100%"
							height="400px"
							data={googleFormattedData}
							options={{
								region: 'US',
								displayMode: 'markers',
								resolution: 'provinces',
								colorAxis: { colors: ['orange', '#4299e1', '#48bb78'] },
								datalessRegionColor: '#f7fafc',
								defaultColor: '#f5f5f5'
							}}
							mapsApiKey="AIzaSyBTwyBk1AFSk8f6KutyWSqWVCu0dhTKbbA"
						/>
						<script src="https://www.gstatic.com/charts/loader.js" />
						<h4 className="mt-4 inline-flex p-2 bg-blue-100 text-blue-500 rounded">
							We have {statesTotal} sites in {tableFormattedData.length} different places.
						</h4>
					</div>
					<div className="md:w-1/3 xs:w-full">
						<h4 className="mb-1">Top States</h4>
						<p className="text-sm text-gray-800 mb-4">Here are the states with the most LeadSites.</p>

						<PieChart data={pieChartData} donut />
					</div>
				</div>
			</>
		);

		const LowerContent = () => (
			<ReactTableFixedColumns
				data={tableFormattedData}
				columns={[
					{
						Header: 'State',
						id: 'state',
						accessor: data => data.name
					},
					{
						Header: 'Total',
						id: 'total',
						accessor: data => data.total
					},
					{
						Header: '% of Users',
						id: 'percent',
						accessor: data => whatPercent(data.total, statesTotal)
					}
				]}
				defaultPageSize={25}
				className="-striped -highlight"
			/>
		);

		return (
			<ReportContainer
				title="What State is most popular?"
				subtitle="Here is the data we have collected for LeadSites built in each state."
				upper={<UpperContent />}
				lower={<LowerContent />}
			/>
		);
	}
}
