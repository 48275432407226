import React, { Component } from "react";
import isEmpty from "lodash.isempty";
import { Helmet } from "react-helmet";
import { navigate } from "gatsby";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import FlexCard from "./FlexCard";
import Loader from "../MainLoader";
import LogoCard from "./LogoCard";
import UserFunctionCard from "./UserFunctionCard";
import CustomAxios from "../../data/axios";
import Site from "./Site";
import "react-tabs/style/react-tabs.css";
import routes from "../../Routes";
import AssociatedEmails from "./AssociatedEmails";
import whatPercent from "../../helpers/whatPercent";
import DisableSiteButton from "./DisableSiteButton";

const toAccounts = () => navigate(routes.APP);

export default class Profile extends Component {
  constructor(props) {
    super(props);
    const siteId = window.location.hash.replace("#", "");

    if (isEmpty(siteId)) {
      toAccounts();
      return;
    }

    this.state = {
      isLoading: true,
      deleteIsLoading: false,
      updateIsLoading: false,
      site: {},
      siteId,
      yPosition: 0,
    };

    this.updateSite = this.updateSite.bind(this);
    this.updateSiteFromEmails = this.updateSiteFromEmails.bind(this);
  }

  componentDidMount() {
    this.getQuery();
  }

  async getQuery() {
    this.setState({ isLoading: true });
    const { siteId } = this.state;
    const data = await CustomAxios.get("site", { siteId });

    if (!isEmpty(data) && !isEmpty(data.site)) {
      const { site } = data;
      this.setState({ site, isLoading: false });
    }
  }

  handleScoreColor = (score) => {
    if (score < 0.6) {
      return "bg-red-500";
    }
    if (score < 2) {
      return "bg-orange-500";
    }
    if (score > 2) {
      return "bg-green-500";
    }
    return "bg-blue-500";
  };

  handleLTVColor = (score) => {
    if (score < 160000) {
      return "border-blue-500";
    }
    if (score > 160000) {
      return "border-green-500";
    }
    return "border-blue-500";
  };

  handleLeadsColor = (score) => {
    if (score < 10) {
      return "border-red-500";
    }
    if (score < 100) {
      return "border-orange-500";
    }
    if (score > 100) {
      return "border-green-500";
    }
    return "border-blue-500";
  };

  handleTrafficColor = (score) => {
    if (score < 1000) {
      return "border-red-500";
    }
    if (score < 2000) {
      return "border-orange-500";
    }
    if (score > 2000) {
      return "border-green-500";
    }
    return "border-blue-500";
  };

  listenToScroll = () => {
    const winScroll =
      window.document.body.scrollTop ||
      window.document.documentElement.scrollTop;

    this.setState({ yPosition: winScroll });
  };

  deleteSite = async () => {
    this.setState({ deleteIsLoading: true });
    const { siteId } = this.state;

    await CustomAxios.post("delete-site", { siteId });
    toAccounts();
  };

  async updateSite() {
    await this.setState({ updateIsLoading: true });
    const { siteId } = this.state;
    await CustomAxios.post("update-site", { siteId });
    await this.setState({ updateIsLoading: false });
  }

  updateSiteFromEmails(site) {
    this.setState({ site });
  }

  render() {
    const {
      siteId,
      isLoading,
      site,
      deleteIsLoading,
      updateIsLoading,
      yPosition,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const data = new Site(site);

    return (
      <section>
        <Helmet>
          <title>{`${data.name} | EAP Brain`}</title>
        </Helmet>
        <div className="flex justify-between items-center bg-gray-800 py-1 px-6 .shadow-inner sticky top-0 z-10">
          <div className="relative flex items-center flex-1">
            <a
              href="/accounts"
              className="flex items-center justify-center p-3 rounded rounded-full hover:bg-gray-700 focus:bg-gray-600 focus:text-white hover:text-white"
            >
              <i className="material-icons">arrow_back</i>
            </a>
            {yPosition > 250 && (
              <span className="hidden w-48 ml-4 text-base font-bold text-white transition xs:hidden sm:hidden md:block">
                {data.name}
              </span>
            )}
          </div>
          <a
            className="inline-flex items-center justify-center flex-1 px-4 py-2 text-base text-white bg-gray-900 rounded hover:bg-blue-600"
            target="blank"
            rel="noopener noreferrer"
            href={data.siteUrl}
          >
            <i className="mr-2 opacity-25 material-icons">link</i>
            {data.siteUrl}
          </a>
          <div className="relative flex justify-end flex-1">
            <a
              href={data.siteUrl}
              target="blank"
              className="flex items-center justify-center p-3 rounded rounded-full hover:bg-gray-700 focus:bg-gray-600 focus:text-white hover:text-white "
            >
              <i className="material-icons">open_in_new</i>
            </a>
          </div>
        </div>
        <section className="relative p-6 pb-24 text-white bg-gray-900 post__hero">
          <div
            className="absolute bottom-0 left-0 right-0 opacity-50"
            style={{
              top: 0,
              backgroundImage: 'url("/bg-pattern.svg")',
              backgroundSize: "cover",
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
            }}
          />
          <div
            className="absolute top-0 bottom-0 left-0 right-0"
            style={{
              backgroundImage: 'url("/bg-pattern-2.svg")',
              backgroundSize: "cover",
              backgroundPosition: "right",
              backgroundRepeat: "no-repeat",
            }}
          />

          <div className="container relative flex flex-wrap justify-between pt-10 m-auto">
            <div className="w-full sm:w-full md:w-2/3">
              <h2 className="flex items-center mb-4 text-3xl text-white">
                {data.name ? data.name : "Not provided"}{" "}
                <span
                  className={`rounded border-2 ${this.handleScoreColor(
                    data.score
                  )} border-white px-2 text-white text-xl ml-4`}
                >
                  {data.score}
                </span>
              </h2>
              <p className="mb-1 text-base text-gray-500">
                Customer since {data.created}
              </p>
              <p className="mb-8 text-base text-gray-500">
                Monthly payment of {data.monthlyAmount}
              </p>
              <p className="flex mb-6 text-base text-white">
                <i className="mr-4 text-xs text-gray-500 material-icons">
                  email
                </i>{" "}
                {data.email}
              </p>
              <p className="flex mb-6 text-base text-white">
                <i className="mr-4 text-xs text-gray-500 material-icons">
                  phone
                </i>
                {data.phone ? data.phone : "Not provided"}
              </p>
              <p className="flex mb-6 text-base text-white">
                <i className="mr-4 text-xs text-gray-500 material-icons">
                  shopping_cart
                </i>
                {data.coupon ? data.coupon : "No coupon"}
              </p>
              <p className="flex mb-6 text-base text-white">
                <i className="mr-4 text-xs text-gray-500 material-icons">map</i>
                {data.address
                  ? `${data.address} ${data.cityStateZip}`
                  : "Not provided"}
              </p>
              <p className="flex mb-6 text-base text-white">
                <i className="mr-4 text-xs text-gray-500 material-icons">
                  http
                </i>
                {data.domain || "No staging site"}
              </p>
              <p className="flex mb-16 text-base text-white">
                <i className="mr-4 text-xs text-gray-500 material-icons">
                  http
                </i>
                {data.multiSite || "No multisite"}
              </p>
            </div>

            <div className="w-full px-4 text-gray-800 sm:w-full md:w-1/3">
              <div className="flex pb-4 text-white">
                <div
                  className={`group flex-1 text-center bg-gray-800 rounded p-2 pt-4 m-1 hover:bg-gray-700 border-t-2 ${this.handleLTVColor(
                    data.lifeTimeValueNum
                  )} `}
                >
                  <div className="text-2xl font-bold">
                    {data.lifeTimeValue || "🚧"}
                  </div>
                  <div className="text-sm opacity-50 group-hover:hidden">
                    LTV
                  </div>
                  <div className="hidden text-sm opacity-50 group-hover:block">
                    LIFETIME VALUE
                  </div>
                </div>
                <div
                  className={`group flex-1 text-center bg-gray-800 rounded p-2 pt-4 m-1 hover:bg-gray-700 border-t-2 ${this.handleLeadsColor(
                    data.leads
                  )} `}
                >
                  <div className="text-2xl font-bold">{data.leads || "🚧"}</div>
                  <div className="text-sm opacity-50 group-hover:hidden">
                    LEADS
                  </div>
                  <div className="hidden text-sm opacity-50 group-hover:block">
                    ALL TIME
                  </div>
                </div>
                <div
                  className={`group flex-1 text-center bg-gray-800 rounded p-2 pt-4 m-1 hover:bg-gray-700 border-t-2 ${this.handleTrafficColor(
                    data.uniqueTraffic
                  )} `}
                >
                  <div className="text-2xl font-bold">
                    {data.uniqueTraffic || "🚧"}
                  </div>
                  <div className="text-sm opacity-50 group-hover:hidden">
                    TRAFFIC
                  </div>
                  <div className="hidden text-sm opacity-50 group-hover:block">
                    LAST 30
                  </div>
                </div>
              </div>
              <div
                className={
                  data.delinquent
                    ? "bg-red-600 border-red-900 text-white shadow rounded border-t-2 border-red-500"
                    : "bg-green-500 text-white shadow rounded overflow-hidden"
                }
              >
                <FlexCard
                  left={
                    !data.delinquent
                      ? "Billing is up to date"
                      : "Alert: Billing is not up to date"
                  }
                />
              </div>
              <div className="flex flex-wrap items-center justify-between p-4 mt-5 bg-gray-800 rounded">
                {data.headshot ? (
                  <div
                    className="w-16 h-16 bg-center bg-no-repeat bg-cover border-2 border-white rounded-full shadow "
                    style={{
                      backgroundImage: `url("${data.headshot}")`,
                    }}
                  />
                ) : (
                  <div className="flex items-center justify-center w-16 h-16 bg-center bg-no-repeat bg-cover rounded-full shadow ">
                    <i
                      style={{ fontSize: "80px" }}
                      className="text-2xl text-gray-600 material-icons"
                    >
                      account_circle
                    </i>
                  </div>
                )}
                {data.logo ? (
                  <span className="p-1 bg-gray-300 rounded shadow">
                    <div
                      className="w-32 h-16 bg-center bg-no-repeat bg-contain"
                      style={{
                        backgroundImage: `url("${data.logo}")`,
                      }}
                    />
                  </span>
                ) : (
                  <div className="flex items-center justify-center w-48 h-16 font-bold text-gray-600 border-2 border-gray-700 rounded">
                    Logo Missing
                  </div>
                )}
              </div>
              <div className="flex items-center justify-end pt-2">
                {data.linkedin && (
                  <a className="p-1" href={data.linkedin}>
                    <img alt="linkedin" src="/linkedin.svg" width="25" />
                  </a>
                )}
                {data.facebook && (
                  <a className="p-1" href={data.facebook}>
                    <img alt="facebook" src="/facebook.svg" width="25" />
                  </a>
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="h-full pb-64 bg-gray-100 post__body">
          <div className="container m-auto">
            <div className="flex flex-wrap">
              <div className="pt-8 post__body__main md:w-2/3 sm:w-full">
                <Tabs>
                  <TabList>
                    <Tab>
                      <span className="flex text-base text-gray-800 align-center">
                        <i
                          style={{ fontSize: "22px" }}
                          className="mr-2 material-icons"
                        >
                          account_circle
                        </i>
                        Accounts
                      </span>
                    </Tab>
                    <Tab>
                      <span className="flex text-base text-gray-800 align-center">
                        <i
                          style={{ fontSize: "22px" }}
                          className="mr-2 material-icons"
                        >
                          email
                        </i>
                        Associated Emails
                      </span>
                    </Tab>
                    <Tab>
                      <span className="flex text-base text-gray-800 align-center">
                        <i
                          style={{ fontSize: "22px" }}
                          className="mr-2 material-icons"
                        >
                          build
                        </i>
                        Tools
                      </span>
                    </Tab>
                    <Tab>
                      <span className="flex text-base text-gray-800 align-center">
                        <i
                          style={{ fontSize: "22px" }}
                          className="mr-2 material-icons"
                        >
                          attach_money
                        </i>
                        Billing
                      </span>
                    </Tab>
                  </TabList>

                  <TabPanel>
                    <div className="p-4 mb-4 ">
                      <h4 className="text-base font-thin text-black-900">
                        User Accounts By App
                      </h4>
                      <p className="mt-4 text-base text-gray-700">
                        These are the internal accounts connected to this user.
                        Click on any account to view this user&apos;s profile in
                        another app.
                      </p>
                    </div>
                    <div className="flex flex-wrap">
                      <LogoCard
                        isAvailable={Boolean(data.stripeId)}
                        name="Baremetrics"
                        link={`https://app.baremetrics.com/customers/stripe/${data.stripeId}`}
                        image="https://baremetrics.com/wp-content/themes/baremetrics/dist/baremetrics-logo.svg"
                      />
                      <LogoCard
                        isAvailable={Boolean(data.stripeId)}
                        name="Stripe"
                        link={`https://dashboard.stripe.com/customers/${data.stripeId}`}
                        image="/stripe.svg"
                      />

                      <LogoCard
                        isAvailable={Boolean(data.zenDeskLink)}
                        name="Zendesk"
                        link={data.zenDeskLink}
                        image="/zendesk.png"
                      />
                      <LogoCard
                        isAvailable={Boolean(data.cloudflareLink)}
                        name="Cloudflare"
                        link={data.cloudflareLink}
                        image="/cloudflare.png"
                      />
                      <LogoCard
                        isAvailable={Boolean(data.agileLink)}
                        name="Agile"
                        link={data.agileLink}
                        image="/agile.webp"
                      />
                      <LogoCard
                        isAvailable={Boolean(data.keapLink)}
                        name="Keap"
                        backgroundSize={120}
                        link={data.keapLink}
                        image="/logo_keap.webp"
                      />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <AssociatedEmails
                      extraEmails={site.extraEmails}
                      siteId={siteId}
                      updateSite={this.updateSiteFromEmails}
                    />
                  </TabPanel>
                  <TabPanel>
                    <div className="p-4 mb-4 ">
                      <h4 className="text-base font-thin text-black-900">
                        User Functions (Get Stuff Done)
                      </h4>
                      <p className="mt-4 text-base text-gray-700">
                        These are the available processes you can start for this
                        user from the dashboard.
                      </p>
                    </div>
                    <div>
                      {data.isDisabled ? (
                        <span>Account is already disabled</span>
                      ) : (
                        <UserFunctionCard
                          title="Disable Account"
                          subtitle="This disables the site in Brain. Useful for idx reporting."
                          button="Refresh Data"
                          EntireButton={() => (
                            <DisableSiteButton siteId={siteId} />
                          )}
                        />
                      )}
                      <UserFunctionCard
                        title="Refresh Account Data?"
                        subtitle="This pull all data from the LeadSite and Stripe. Useful for checking recent activity or if you notice that something is missing."
                        button="Refresh Data"
                        onClick={this.updateSite}
                        isLoading={updateIsLoading}
                      />
                      <UserFunctionCard
                        isLoading={deleteIsLoading}
                        title="Remove this record from the customer database?"
                        subtitle="This will remove any records of this user from our admin database. Before you do this, please deactivate the site in IHF, EAP RETS, and from the admin panel on the wp-multisite. Otherwise, this record will reappear in the admin panel within 24-48 hours. No need to worry about accidentally deleteting the customer record permanently. 😅 "
                        button="Delete Site"
                        onClick={() => this.deleteSite()}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="p-4 mb-4 ">
                      <h4 className="text-base font-thin text-black-900">
                        Billing Summary
                      </h4>
                      <p className="mt-4 text-base text-gray-700">
                        Here is the summary of all billing related information
                        we have for this user.
                      </p>
                      <div className="mt-6 overflow-hidden bg-white border-t-2 border-blue-500 rounded shadow">
                        <FlexCard
                          left="User signed up"
                          right={data.created || "🚧"}
                        />
                        <FlexCard
                          left="Last payment"
                          right={data.latestPayment || "🚧"}
                        />
                        <FlexCard
                          left="Stripe Id"
                          right={data.stripeId || "🚧"}
                        />
                        <FlexCard
                          left="Total monthly amount"
                          right={data.monthlyAmount || "🚧"}
                        />
                        <FlexCard
                          left="Lifetime Value (LTV)"
                          right={data.lifeTimeValue || "🚧"}
                        />
                        <FlexCard
                          left="Cancel reason"
                          right={data.cancelReason || "🚧"}
                        />
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
              <div className="w-full p-4 post__body__sidebar sm:w-full md:w-1/3">
                <div className="overflow-hidden bg-white border-t-2 border-blue-500 rounded shadow">
                  <h4 className="block py-4 pt-6 mb-4 text-center">
                    Account Information
                  </h4>
                  <h4 className="flex items-center block p-4 m-0 text-sm font-thin text-left text-blue-500 uppercase border-b">
                    <i className="mr-2 text-base material-icons">apps</i> App
                    Usage
                  </h4>
                  <FlexCard left="Squeezes" right={data.squeezes || "🚧"} />
                  <FlexCard left="Popups" right={data.popups || "0"} />
                  <FlexCard left="Vendors" right={data.vendors || "0"} />
                  <FlexCard left="InstaFarms" right={data.farms || "🚧"} />
                  <FlexCard left="Team" right={data.team || "🚧"} />
                  <FlexCard
                    left="Total Blog Posts"
                    right={data.posts || "🚧"}
                  />
                  <FlexCard left="Texty Codes" right={data.textyCodes || "0"} />
                  <FlexCard
                    left="Homepage Template"
                    right={data.homesTemplate || "🚧"}
                  />
                  <h4 className="flex items-center block p-4 m-0 mt-4 text-sm font-thin text-left text-blue-500 uppercase border-b">
                    <i className="mr-2 text-base material-icons">explore</i>{" "}
                    LeadSite Stats
                  </h4>
                  <FlexCard
                    left="Onboarding Completed"
                    right={`${whatPercent(data.statsPercentage, 1)}%` || "0%"}
                  />
                  <FlexCard left="Total Leads" right={data.leads || "🚧"} />
                  <FlexCard
                    left="Leads Last 30"
                    right={data.leadsLast30 || "🚧"}
                  />
                  <FlexCard
                    left="Traffic Last 30"
                    right={data.uniqueTraffic || "🚧"}
                  />
                  <FlexCard
                    left="Conversion"
                    right={
                      data.leadsToTraffic
                        ? `${whatPercent(data.leadsToTraffic, 1)}%`
                        : "Unknown"
                    }
                  />
                  <FlexCard
                    left="Lifetime Support Tickets"
                    right={data.ticketsLifeTime || "🚧"}
                  />

                  <h4 className="flex items-center block p-4 m-0 mt-4 text-sm font-thin text-left text-blue-500 uppercase border-b">
                    <i className="mr-2 text-base material-icons">home</i> IDX
                  </h4>
                  <FlexCard left="IHF Active" right={data.ihfActive || "🚧"} />
                  <FlexCard
                    left="EAP Rets Active"
                    right={data.eapRetsActive || "🚧"}
                  />
                  {/*  */}
                  <FlexCard left="MLS Name" right={data.mlsName || "🚧"} />
                  <FlexCard left="Agent ID" right={data.agentID || "🚧"} />
                  <FlexCard
                    left="Broker Name"
                    right={data.brokerName || "🚧"}
                  />
                  <FlexCard
                    left="Broker Email"
                    right={data.brokerEmail || "🚧"}
                  />
                  <FlexCard left="Office ID" right={data.officeID || "🚧"} />
                  {/*  */}
                  <h4 className="flex items-center block p-4 m-0 mt-4 text-sm font-thin text-left text-blue-500 uppercase border-b">
                    <i className="mr-2 text-base material-icons">
                      attach_money
                    </i>{" "}
                    Billing
                  </h4>
                  <FlexCard
                    left="Monthly Amount"
                    right={data.monthlyAmount || "🚧"}
                  />
                  <FlexCard
                    left="Lifetime Value"
                    right={data.lifeTimeValue || "🚧"}
                  />
                  <FlexCard left="Score" right={data.score || "🚧"} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}
