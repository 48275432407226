const phoneFilter = (filter, row) => {
	try {
		const rowPhone = row[filter.id].replace(/\D/g, '');
		const value = filter.value.replace(/\D/g, '');
		return rowPhone.includes(value);
	} catch (err) {
		return false;
	}
};

export default phoneFilter;
