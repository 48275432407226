import React from 'react';
import PropTypes from 'prop-types';

function EventTag(props) {
	const { name, icon, danger } = props;
	return (
		<div className="flex items-center">
			<i style={{ fontSize: 20, lineHeight: 0 }} className={`material-icons mr-2 ${danger ? 'text-red-400' : 'text-blue-400'}`}>
				{icon}
			</i>
			{name}
		</div>
	);
}

EventTag.propTypes = {
	name: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	danger: PropTypes.bool,
};

EventTag.defaultProps = {
	danger: false,
};

export default EventTag;
