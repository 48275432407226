import React from 'react';

export default function BooleanSelect(props) {
	const { onChange, filter } = props;
	return (
		<select onChange={event => onChange(event.target.value)} style={{ width: '100%' }} value={filter ? filter.value : 'all'}>
			<option value="all">All</option>
			<option value="true">True</option>
			<option value="false">False</option>
		</select>
	);
}
