import React from 'react';
import PropTypes from 'prop-types';

export default function InputFilter(props) {
	const { onChange, filter } = props;

	return (
		<div className="flex flex-wrap">
			<input
				className="w-full"
				type="number"
				value={filter.value}
				placeholder="NOT log in in X Days"
				onChange={event => onChange(event.target.value)}
			/>
		</div>
	);
}

InputFilter.propTypes = {
	onChange: PropTypes.func.isRequired,
	filter: PropTypes.object,
};

InputFilter.defaultProps = {
	filter: { value: { min: 0, max: '' } },
};
