import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomAxios from '../../data/axios';

export default function AssociatedEmails(props) {
	const { extraEmails, siteId, updateSite } = props;

	const [emails, updateEmails] = useState(extraEmails);
	const [isLoading, changeLoader] = useState(false);

	const saveEmails = async e => {
		e.preventDefault();
		changeLoader(true);
		try {
			const data = await CustomAxios.post('site-emails', { siteId, extraEmails: emails });
			const { site } = data;
			if (site) {
				updateSite(site);
			}
		} catch (err) {
			console.warn({ err });
		}
		changeLoader(false);
	};

	const addEmail = e => {
		e.preventDefault();
		updateEmails([
			...emails,
			{
				id: emails.length,
				value: '',
			},
		]);
	};

	const handleChange = e => {
		const { value, id } = e.target;
		const newEmails = emails.map(email => {
			if (email.id === parseInt(id, 10)) {
				return { id: parseInt(id, 10), value };
			}
			return email;
		});
		updateEmails(newEmails);
	};

	const removeEmail = id => {
		const newEmails = emails.filter(email => {
			if (email.id !== id) {
				return email;
			}
			return false;
		});
		const reIndexed = newEmails.map((email, i) => {
			return { id: i, value: email.value };
		});
		updateEmails(reIndexed);
	};

	// console.warn({ emails });
	return (
		<div className="flex flex-wrap">
			<div className="w-1/2 p-4 md:w-100">
				<h4 className="font-thin text-base text-black-900">Associated Email Addresses</h4>
				<p className="text-base mt-4 text-gray-700">
					These are extra account emails that need to be associated with the user depending on the situation.
				</p>
			</div>
			<form className="w-1/2 p-4" onSubmit={saveEmails}>
				{emails.map(email => (
					<div key={email.id}>
						<label className="font-thin text-base mb-2 block text-gray-800">Account #{email.id + 1}</label>
						<input
							id={email.id}
							onChange={handleChange}
							className="block border focus:border-blue-500 py-2 px-4 mb-1 w-full rounded"
							placeholder="Email address"
							value={email.value}
						/>
						<button className="bg-red-500 px-4 text-white" type="button" onClick={() => removeEmail(email.id)}>
							Remove
						</button>
					</div>
				))}
				<div className="p-4 block text-right">
					<button type="button" className="button" onClick={addEmail}>
						Add Email
					</button>
				</div>
				<div className="p-4 block text-center">
					<button disabled={isLoading} type="submit" className="button">
						{!isLoading ? 'Save changes' : 'Loading...'}
					</button>
				</div>
			</form>
		</div>
	);
}

AssociatedEmails.propTypes = {
	extraEmails: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			value: PropTypes.string,
		})
	),
	siteId: PropTypes.string.isRequired,
	updateSite: PropTypes.func.isRequired,
};

AssociatedEmails.defaultProps = {
	extraEmails: [],
};
