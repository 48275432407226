import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/lib/Async';
import debounce from 'debounce-async';
import CustomAxios from '../../data/axios';

export default function ZendeskTagSelector(props) {
	const [selectedTag, setselectedTag] = useState('');
	const [isShowing, setisShowing] = useState(false);
	const toReactSelect = tags => {
		return tags.map(tag => ({ value: tag, label: tag }));
	};

	const callServerForTags = async search => {
		try {
			const results = await CustomAxios.get('tags', { search });
			const { tags } = results;
			return toReactSelect(tags);
		} catch (err) {
			console.error(err);
			return toReactSelect([]);
		}
	};

	const handleSelect = value => {
		setselectedTag(value);
	};

	const submitForm = async event => {
		event.preventDefault();
		const { getStuffFromTable } = props;
		const contacts = getStuffFromTable();
		console.warn(`tagging ${selectedTag.value} on`);

		await Promise.all(
			contacts.map(async contact => {
				await CustomAxios.post('tags', {
					zendeskID: contact.zendesk_id,
					tag: selectedTag.value
				});
			})
		);
	};

	return (
		<form onSubmit={submitForm} className="w-full m-0">
		
	  <div style={{ backgroundImage: 'url("./zendesk.png")' }} className="w-full h-6 bg-no-repeat bg-contain bg-center mb-4" />
			<AsyncSelect
				onChange={handleSelect}
				classNamePrefix="eap-select"
				placeholder="ie. zopim_chat"
				loadOptions={debounce(callServerForTags, 500)}
				value={selectedTag}
			/>
			{/* @robert = show disabled with css */}

			<button
				disabled={!selectedTag || selectedTag === ''}
				className="button cursor-pointer w-full"
				type="submit"
				style={{ borderRadius: '0 0 4px 4px' }}
			>
				{!selectedTag ? 'Search for a tag above' : 'Tag All Visible Users'}
			</button>
		</form>
	);
}

ZendeskTagSelector.propTypes = {
	getStuffFromTable: PropTypes.func.isRequired
};
