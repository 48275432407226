import React, { useState } from "react";
import Loader from "./MainLoader";
import CustomAxios from "../data/axios";

export default function Search() {
  const [isLoading, updateLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [email, setResult] = useState({});
  const [error, setError] = useState(false);

  const searchServer = async (event) => {
    event.preventDefault();
    updateLoading(true);
    try {
      const response = await CustomAxios.get("email-search", { email: search });
      console.warn(response);
      setResult(response.emails || {});
      if (!response.emails) {
        setError(true);
      } else {
        setError(false);
      }
    } catch (errors) {
      console.error(errors);
    }
    updateLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="p-4">
      <form className="m-auto max-w-md pt-20 py-12" onSubmit={searchServer}>
        <h2 className="text-2xl text-gray-900 mb-2">Find someone by email</h2>
        <p className="text-xs text-gray-500 pb-4">
          This currently search email addresses from Zendesk, Stripe, Airtable,
          and Brain. If they have more than one stripe ID, it shows the first
          one. It searches 3 email fields on Brain. The one email field on
          Zendesk and the one email field on Stripe. Some Stripes are missing
          emails. Some Airtables have multiple emails.
        </p>
        <label className="block label font-bold mb-2" htmlFor="email">
          Search by email
        </label>
        <input
          placeholder="ie. josh@gmail.com"
          className="block input w-full border mb-6 px-4 py-2 rounded focus:shadow focus:border-blue-500"
          type="email"
          id="email"
          name="email"
          required
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <button className="block button" type="submit">
          Search
        </button>
        {error && <p className="mt-4 text-red-500 text-sm">Nothing found.</p>}
      </form>

      <div className="flex flex-wrap max-w-md m-auto">
        {email.stripeId && (
          <a
            className="mx-2 my-2 button"
            target="_blank"
            rel="noopener noreferrer"
            href={`https://dashboard.stripe.com/customers/${email.stripeId}`}
          >
            Stripe Profile
          </a>
        )}
        {email.siteKey && (
          <a
            className="mx-2 my-2 button"
            href={`/accounts/profile#${email.siteKey}`}
          >
            Brain Profile
          </a>
        )}
        {email.airtableId && (
          <a
            className="mx-2 my-2 button"
            target="_blank"
            rel="noopener noreferrer"
            href={`https://airtable.com/tblE3n0zHsVpzKOtR/viw2RzZFmHnORQAvi/${email.airtableId}`}
          >
            Airtable
          </a>
        )}
        {email.zendeskId && (
          <a
            className="mx-2 my-2 button"
            target="_blank"
            rel="noopener noreferrer"
            href={`https://easyagentpro.zendesk.com/agent/users/${email.zendeskId}/requested_tickets`}
          >
            Zendesk
          </a>
        )}
      </div>
    </div>
  );
}
