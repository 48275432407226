import React from 'react';
import PropTypes from 'prop-types';

export default function DateDisplay(props) {
	const { value } = props;
	if (!value) {
		return <span className="text-red-500">None</span>;
	}
	return <span className="">{value}</span>;
}

DateDisplay.propTypes = {
	value: PropTypes.string,
};

DateDisplay.defaultProps = {
	value: null,
};
