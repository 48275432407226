import Fuse from 'fuse.js';

const stringFilter = (filter, rows) => {
	const options = {
		threshold: 0.2,
		location: 0,
		distance: 100,
		maxPatternLength: 32,
		minMatchCharLength: 1,
		keys: [filter.id],
	};
	const fuse = new Fuse(rows, options); // "list" is the item array
	return fuse.search(filter.value);
};

export const exactMatchFilter = (filter, rows) => {
	console.warn(rows);
	return rows.filter(row => row.siteUrl && row.siteUrl.toUpperCase().includes(filter.value.toUpperCase()));
};

export default stringFilter;
