import React, { Component } from "react";
import isEmpty from "lodash.isempty";
import ReactTable from "react-table";
import { Helmet } from "react-helmet";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";
import Loader from "../MainLoader";
import "react-table/react-table.css";
import BooleanSelect from "./BooleanSelect";
import DelinquentSelect from "./DelinquentSelect";
import CustomAxios from "../../data/axios";
import toUrl from "../../helpers/toUrl";
import toMoney from "../../helpers/toMoney";
import booleanFilter from "./BooleanFilter";
import rangeFilter from "./rangeFilter";
import RangeSelect from "./RangeSelect";
import UserProfileIcon from "./userProfileIcon";
import stringFilter from "./StringFilter";
import phoneFilter from "./PhoneFilter";
import stateFilter from "./StateSelect/StateFilter";
import domainFilter from "./DomainFilter";
import Domain from "./Domain";
import StripeLink from "./StripeLink";
import toTime from "../../helpers/toTime";
import ZendeskId from "./ZendeskId";
import AgileId from "./AgileId";
import KeapId from "./KeapId";
import CloudFlareId from "./CloudflareId";
import StateSelect from "./StateSelect/StateSelect";
import ExportCsv from "./ExportCsv";
import PercentagePicker from "./PercentagePicker";
import whatPercent from "../../helpers/whatPercent";
import userIsActive from "../../helpers/userIsActive";
import AddATag from "./AddATag";
import MinMaxSelect from "./MinMaxSelect";
import MinMaxFilter from "./MinMaxFilter";
import DateDisplay from "./DateDisplay";
import InputFilter from "./InputFilter";
import InputFilterLogic from "./InputFilterLogic";
import UserIsActive from "./UserIsActiveFilter";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export default class ListOfAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      total: null,
      sites: [],
      filterByPercentage: "all",
    };
    this.getCurrentRecords = this.getCurrentRecords.bind(this);
    this.changeFilterPercentage = this.changeFilterPercentage.bind(this);
  }

  componentDidMount() {
    this.getQuery();
  }

  getCurrentRecords() {
    return this.selectTable.getResolvedState().sortedData;
  }

  async getQuery() {
    this.setState({ isLoading: true });

    const data = await CustomAxios.get("sites", {});
    console.log('sites', data);

    if (data.sites) {
      const { sites } = data;
      this.setState({
        sites,
        total: typeof sites !== "undefined" ? sites.length : 0,
        isLoading: false,
      });
    }
  }

  changeFilterPercentage(e) {
    const { value } = e.target;
    this.setState({ filterByPercentage: value });
  }

  filteredSites() {
    const { sites, filterByPercentage, total } = this.state;
    function highestToLowest(a, b) {
      return a.score > b.score ? -1 : 1;
    }
    sites.sort(highestToLowest);

    switch (filterByPercentage) {
      case "bot5":
        return sites.slice(Math.max(sites.length - total * 0.05, 1));
      case "top20":
        // get first total * .20
        return sites.slice(0, total * 0.2);
      case "mid75":
        // skip first total * 20 and get next total * .75
        return sites.slice(total * 0.2 - 1, sites.length - 1 - total * 0.05);
      case "all":
      default:
        return sites;
    }
  }

  render() {
    const { isLoading, total, filterByPercentage } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <section className="p-4">
        <Helmet>
          <title>Accounts | EAP Brain</title>
        </Helmet>
        <div className="flex justify-between pb-6">
          <div>
            <h2>{`Total: ${total}`}</h2>
            <p>Hold shift to sort by multiple columns</p>
          </div>
          <span className="flex items-start">
            <PercentagePicker
              value={filterByPercentage}
              changeSelect={this.changeFilterPercentage}
            />

            <ExportCsv
              className="mr-2 flex-grow-0 flex-shrink-0"
              getStuffFromTable={this.getCurrentRecords}
            />
            {/* <AgileForm getStuffFromTable={this.getCurrentRecords} />
						<ZendeskTagSelector getStuffFromTable={this.getCurrentRecords} /> */}
            <AddATag data={this.getCurrentRecords} />
          </span>
        </div>
        <div>
          <ReactTableFixedColumns
            innerRef={(ref) => {
              this.selectTable = ref;
            }}
            data={this.filteredSites()}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            columns={[
              {
                Header: "Info",
                fixed: "left",
                columns: [
                  {
                    Header: "",
                    accessor: (act) => act,
                    Cell: ({ value }) =>
                      <UserProfileIcon
                        id={value.siteId}
                        name={value.name}
                        email={value.email}
                        phone={value.phone}
                        url={toUrl(value.domain)}
                        img={value.headshot}
                      /> ? (
                        <UserProfileIcon
                          id={value.siteId}
                          name={value.name}
                          email={value.email}
                          phone={value.phone}
                          url={toUrl(value.domain)}
                          img={value.headshot}
                        />
                      ) : (
                        <div>hi</div>
                      ),
                    id: "profile",
                    filterable: false,
                    style: {
                      overflow: "visible",
                      padding: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    },
                    width: 65,
                  },
                ],
              },
              {
                Header: "Info",
                columns: [
                  {
                    Header: "Email Address",
                    accessor: (act) => act.email,
                    id: "email",
                    filterMethod: (filter, rows) => stringFilter(filter, rows),
                    filterAll: true,
                    width: 250,
                  },
                  {
                    Header: "Name",
                    id: "name",
                    accessor: (act) => (act.name ? act.name : ""),
                    filterMethod: (filter, rows) => stringFilter(filter, rows),
                    filterAll: true,
                    width: 200,
                  },
                  {
                    Header: "Company",
                    id: "company",
                    accessor: (act) => (act.company ? act.company : ""),
                    filterMethod: (filter, rows) => stringFilter(filter, rows),
                    filterAll: true,
                    width: 200,
                  },
                  {
                    Header: "Phone",
                    id: "phone",
                    accessor: (act) => (act.phone ? act.phone : ""),
                    filterMethod: (filter, row) => phoneFilter(filter, row),
                    width: 150,
                  },
                  {
                    Header: "Monthly Payment",
                    id: "monthly",
                    Cell: ({ value }) => toMoney(value),
                    accessor: (act) =>
                      act.monthlyAmount ? act.monthlyAmount : 0,
                    filterMethod: (filter, row) => MinMaxFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <MinMaxSelect onChange={onChange} filter={filter} />
                    ),
                    width: 150,
                  },
                  {
                    Header: "Last Pmt",
                    Cell: ({ value }) => toTime(value),
                    accessor: "latestPayment",
                    filterable: false,
                    width: 150,
                  },
                ],
              },
              {
                Header: "Stripe",
                columns: [
                  {
                    Header: "Stripe",
                    Cell: ({ value }) =>
                      !isEmpty(value) ? (
                        <StripeLink value={value} />
                      ) : (
                        <span className="text-red-500">● Error</span>
                      ),
                    accessor: "stripe_id",
                    filterMethod: (filter, row) => booleanFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <BooleanSelect filter={filter} onChange={onChange} />
                    ),
                  },
                  {
                    Header: "Cancelled",
                    Cell: ({ value }) => toTime(value),
                    accessor:
                      "customerHasNoActiveSubscriptionsAndWasCancelledAt",
                    filterable: false,
                    width: 150,
                  },
                  {
                    Header: "Cancel reason",
                    accessor: "cancelReason",
                    id: "cancelReason",
                    filterMethod: (filter, rows) => stringFilter(filter, rows),
                    filterAll: true,
                  },
                  {
                    Header: "Last Login",
                    accessor: ({ lastNonEapSignIn }) =>
                      !lastNonEapSignIn || lastNonEapSignIn === "false"
                        ? null
                        : lastNonEapSignIn,
                    id: "lastNonEapSignIn",
                    Cell: ({ value }) => <DateDisplay value={value} />,
                    filterMethod: (filter, row) =>
                      InputFilterLogic(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <InputFilter filter={filter} onChange={onChange} />
                    ),
                    width: 150,
                  },
                  {
                    Header: "Signed Up",
                    Cell: ({ value }) => toTime(value),
                    accessor: "created",
                    filterable: false,
                    width: 150,
                  },
                  {
                    Header: "Coupon code",
                    width: 150,
                    id: "coupon",
                    accessor: (act) => (act.coupon ? act.coupon : ""),
                    filterMethod: (filter, rows) => stringFilter(filter, rows),
                    filterAll: true,
                  },
                  {
                    Header: "Lifetime value",
                    Cell: ({ value }) => toMoney(value),
                    accessor: "lifeTimeValue",
                    filterMethod: (filter, row) => MinMaxFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <MinMaxSelect onChange={onChange} filter={filter} />
                    ),
                    width: 150,
                  },
                  {
                    Header: "Payment Status",
                    accessor: (act) => act,
                    Cell: ({ value }) =>
                      userIsActive(value) ? (
                        <span className="text-green-500">Up To Date</span>
                      ) : (
                        <span className="text-red-500">● Delinquent</span>
                      ),
                    id: "payment",
                    width: 150,
                    filterMethod: (filter, row) => UserIsActive(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <DelinquentSelect filter={filter} onChange={onChange} />
                    ),
                  },
                  {
                    Header: "Expired",
                    accessor: "leadsite_expires_after_date",
                    id: "leadsite_expires_after_date",
                    width: 150,
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value === "true") {
                        return !!row[filter.id];
                      }

                      return !row[filter.id];
                    },
                    Filter: ({ filter, onChange }) => (
                      <BooleanSelect filter={filter} onChange={onChange} />
                    ),
                  },
                  {
                    Header: "No pay",
                    accessor: "noPay",
                    id: "noPay",
                    width: 150,
                    Cell: ({ value }) =>
                      value ? (
                        <span className="text-red-500">● No pay enabled</span>
                      ) : (
                        <span> </span>
                      ),
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value === "true") {
                        return !!row[filter.id];
                      }

                      return !row[filter.id];
                    },
                    Filter: ({ filter, onChange }) => (
                      <BooleanSelect filter={filter} onChange={onChange} />
                    ),
                  },
                ],
              },
              {
                Header: "Enabled Services",
                columns: [
                  {
                    Header: "ZD ID",
                    accessor: "zendesk_id",
                    Cell: ({ value }) =>
                      value ? (
                        <ZendeskId ID={value} />
                      ) : (
                        <span className="text-red-500">● Error</span>
                      ),
                    filterable: false,
                    width: 100,
                  },
                  {
                    Header: "Tickets",
                    accessor: "ticketsLifeTime",
                    filterMethod: (filter, row) => MinMaxFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <MinMaxSelect onChange={onChange} filter={filter} />
                    ),
                    width: 100,
                  },
                  {
                    Header: "Agile ID",
                    accessor: "agile_id",
                    Cell: ({ value }) =>
                      value ? (
                        <AgileId ID={value} />
                      ) : (
                        <span className="text-red-500">● Error</span>
                      ),
                    filterable: false,
                    width: 100,
                  },
                  {
                    Header: "Keap ID",
                    accessor: "keap_id",
                    Cell: ({ value }) =>
                      value ? (
                        <KeapId ID={value} />
                      ) : (
                        <span className="text-red-500">● Error</span>
                      ),
                    filterable: false,
                    width: 100,
                  },
                  {
                    Header: "IHF",
                    accessor: "ihfActive",
                    Cell: ({ value }) => (value ? "True" : "False"),
                    filterMethod: (filter, row) => booleanFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <BooleanSelect filter={filter} onChange={onChange} />
                    ),
                    width: 100,
                  },

                  {
                    Header: "EAP Rets",
                    id: "eapRetsActive",
                    Cell: ({ value }) => (value ? "True" : "False"),
                    accessor: (act) => act.eapRetsActive,
                    filterMethod: (filter, row) => booleanFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <BooleanSelect filter={filter} onChange={onChange} />
                    ),
                    width: 100,
                  },
                  {
                    Header: "Ninja Score",
                    accessor: "score",
                    filterMethod: (filter, row) => MinMaxFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <MinMaxSelect onChange={onChange} filter={filter} />
                    ),
                    width: 150,
                  },
                  {
                    Header: "Site Score",
                    accessor: (act) =>
                      act.statsPercentage ? act.statsPercentage : 0,
                    Cell: ({ value }) => Math.floor(value * 100) / 100,
                    id: "statsPercentage",
                    filterMethod: (filter, row) => MinMaxFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <MinMaxSelect onChange={onChange} filter={filter} />
                    ),
                    width: 150,
                  },
                ],
              },
              {
                Header: "Stats",
                columns: [
                  {
                    Header: "Traffic Last 30",
                    id: "uniqueTraffic",
                    accessor: (act) => act.uniqueTraffic,
                    filterMethod: (filter, row) => MinMaxFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <MinMaxSelect filter={filter} onChange={onChange} />
                    ),
                    width: 150,
                  },
                  {
                    Header: "Leads Last 30",
                    accessor: "leadsLast30",
                    filterMethod: (filter, row) => MinMaxFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <MinMaxSelect filter={filter} onChange={onChange} />
                    ),
                    width: 150,
                  },
                  {
                    Header: "Leads All Time",
                    accessor: "leads",
                    filterMethod: (filter, row) => MinMaxFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <MinMaxSelect filter={filter} onChange={onChange} />
                    ),
                    width: 150,
                  },
                  {
                    Header: "Conversion",
                    accessor: "leadsToTraffic",
                    Cell: ({ value }) =>
                      value ? `${whatPercent(value, 1)}%` : "Unknown",
                    filterMethod: (filter, row) => rangeFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <RangeSelect filter={filter} onChange={onChange} />
                    ),
                    width: 150,
                  },
                  {
                    Header: "Crm Enabled",
                    accessor: "crmEnabled",
                    filterMethod: (filter, row) => booleanFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <BooleanSelect filter={filter} onChange={onChange} />
                    ),
                    width: 150,
                  },
                  {
                    Header: "Crm Campaigns",
                    accessor: "crmCampaignsTotal",
                    filterMethod: (filter, row) => MinMaxFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <MinMaxSelect filter={filter} onChange={onChange} />
                    ),
                    width: 100,
                  },
                  {
                    Header: "Crm SMS Sent (last 30)",
                    accessor: "crmSmsLast30",
                    filterMethod: (filter, row) => MinMaxFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <MinMaxSelect filter={filter} onChange={onChange} />
                    ),
                    width: 100,
                  },
                  {
                    Header: "Crm Email Sent (last 30)",
                    accessor: "crmEmailsLast30",
                    filterMethod: (filter, row) => MinMaxFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <MinMaxSelect filter={filter} onChange={onChange} />
                    ),
                    width: 100,
                  },
                  {
                    Header: "Farms",
                    accessor: "farms",
                    filterMethod: (filter, row) => MinMaxFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <MinMaxSelect filter={filter} onChange={onChange} />
                    ),
                    width: 100,
                  },
                  {
                    Header: "Posts",
                    accessor: "posts",
                    filterMethod: (filter, row) => MinMaxFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <MinMaxSelect filter={filter} onChange={onChange} />
                    ),
                    width: 100,
                  },

                  {
                    Header: "Squeezes",
                    accessor: "squeezes",
                    filterMethod: (filter, row) => MinMaxFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <MinMaxSelect filter={filter} onChange={onChange} />
                    ),
                    width: 100,
                  },
                  {
                    Header: "Single Properties",
                    accessor: "properties",
                    filterMethod: (filter, row) => MinMaxFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <MinMaxSelect filter={filter} onChange={onChange} />
                    ),
                    width: 100,
                  },
                  {
                    Header: "Pop-Ups",
                    accessor: "popups",
                    filterMethod: (filter, row) => MinMaxFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <MinMaxSelect filter={filter} onChange={onChange} />
                    ),
                    width: 100,
                  },
                  {
                    Header: "Team Members",
                    id: "team",
                    accessor: (act) => act.team,
                    filterMethod: (filter, row) => MinMaxFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <MinMaxSelect filter={filter} onChange={onChange} />
                    ),
                    width: 150,
                  },
                  {
                    Header: "Market Reports",
                    id: "listingReports",
                    accessor: (act) => act.listingReports,
                    filterMethod: (filter, row) => MinMaxFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <MinMaxSelect filter={filter} onChange={onChange} />
                    ),
                    width: 150,
                  },
                  {
                    Header: "Testimonials",
                    id: "testimonials",
                    accessor: (act) => act.testimonials,
                    filterMethod: async (filter, row) =>
                      MinMaxFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <MinMaxSelect filter={filter} onChange={onChange} />
                    ),
                    width: 150,
                  },
                  {
                    Header: "Vendors",
                    accessor: "vendors",
                    filterMethod: (filter, row) => MinMaxFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <MinMaxSelect filter={filter} onChange={onChange} />
                    ),
                    width: 100,
                  },
                  {
                    Header: "Homepage",
                    accessor: "homesTemplate",
                    Cell: ({ value }) => (
                      <div>{value === "false" ? "Classic" : value}</div>
                    ),
                    width: 100,
                  },
                  {
                    Header: "Custom Texty",
                    accessor: "customTextyNumber",
                    width: 100,
                    filterable: false,
                  },
                  {
                    Header: "TextyCodes",
                    accessor: "texty",
                    filterMethod: (filter, row) => MinMaxFilter(filter, row),
                    Filter: ({ filter, onChange }) => (
                      <MinMaxSelect filter={filter} onChange={onChange} />
                    ),
                    width: 100,
                  },
                  {
                    Header: "Skills",
                    accessor: "skillz",
                    filterable: false,
                    width: 100,
                  },
                  {
                    Header: "Facebook",
                    accessor: "facebook",
                    Cell: ({ value }) =>
                      value ? <a href={value}>Facebook →</a> : "Not provided",
                    filterable: false,
                    width: 150,
                  },
                  {
                    Header: "LinkedIn",
                    accessor: "linkedin",
                    Cell: ({ value }) =>
                      value ? <a href={value}>LinkedIn →</a> : "Not provided",
                    filterable: false,
                    width: 150,
                  },
                ],
              },
              {
                Header: "Address",
                columns: [
                  {
                    Header: "State",
                    id: "state",
                    accessor: (act) => act.state,
                    filterMethod: (filter, rows) => stateFilter(filter, rows),
                    Filter: ({ filter, onChange }) => (
                      <StateSelect filter={filter} onChange={onChange} />
                    ),
                  },
                  {
                    Header: "Address",
                    id: "address",
                    accessor: (act) => act.address,
                    filterable: false,
                    width: 250,
                  },
                  {
                    Header: "City/State/Zip",
                    id: "zip",
                    accessor: (act) => act.cityStateZip,
                    filterable: false,
                    width: 150,
                  },
                ],
              },
              {
                Header: "IDX Profile",
                columns: [
                  {
                    Header: "MLS Name",
                    id: "mlsName",
                    accessor: (act) => act.mlsName,
                    filterMethod: (filter, rows) => stringFilter(filter, rows),
                    width: 250,
                  },
                  {
                    Header: "Agent ID",
                    id: "agentID",
                    accessor: (act) => act.agentID,
                    filterMethod: (filter, rows) => stringFilter(filter, rows),
                    width: 250,
                  },
                  {
                    Header: "Broker Name",
                    id: "brokerName",
                    accessor: (act) => act.brokerName,
                    filterMethod: (filter, rows) => stringFilter(filter, rows),
                    width: 250,
                  },

                  {
                    Header: "Broker Email",
                    id: "brokerEmail",
                    accessor: (act) => act.brokerEmail,
                    filterMethod: (filter, rows) => stringFilter(filter, rows),
                    width: 250,
                  },
                  {
                    Header: "Office ID",
                    id: "officeID",
                    accessor: (act) => act.officeID,
                    filterMethod: (filter, rows) => stringFilter(filter, rows),
                    width: 250,
                  },
                ],
              },
              {
                Header: "Domain",
                columns: [
                  {
                    Header: "Username",
                    id: "username",
                    accessor: (act) => act.username,
                    filterMethod: (filter, rows) => stringFilter(filter, rows),
                    width: 250,
                  },
                  {
                    Header: "Site Url",
                    id: "siteUrl",
                    accessor: (act) => act.siteUrl,
                    Cell: ({ value }) => <Domain url={value} />,
                    filterMethod: (filter, rows) => domainFilter(filter, rows),
                    width: 250,
                  },
                  {
                    Header: "Custom Domain",
                    id: "mappedDomain",
                    accessor: (act) => act.mappedDomain,
                    Cell: ({ value }) => <Domain url={value} />,
                    filterMethod: (filter, rows) => domainFilter(filter, rows),
                    width: 250,
                  },

                  {
                    Header: "Staging Domain",
                    id: "domain",
                    accessor: (act) => act.domain,
                    Cell: ({ value }) => <Domain url={value} />,
                    filterMethod: (filter, rows) => domainFilter(filter, rows),
                    width: 250,
                  },
                  {
                    Header: "Multisite",
                    id: "multisite",
                    accessor: (act) => act.multisite,
                    Cell: ({ value }) => <Domain url={value} />,
                    filterMethod: (filter, rows) => domainFilter(filter, rows),
                    width: 250,
                  },
                  {
                    Header: "CF",
                    id: "cloudflare_id",
                    accessor: (act) => act,
                    Cell: ({ value }) =>
                      value.cloudflare_id ? (
                        <CloudFlareId domain={value.mappedDomain} />
                      ) : (
                        <span className="text-red-500">● Error</span>
                      ),
                    filterable: false,
                    width: 100,
                  },
                ],
              },
            ]}
            defaultPageSize={25}
            className="-striped -highlight"
            style={{
              height: "85vh",
            }}
          />
        </div>
      </section>
    );
  }
}
