import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import FlexCard from "../../Profile/FlexCard";

const HomepageCard = (props) => {
  const { template, count, image, percent } = props;
  return (
    <HomepageCardContainer>
      <div className="shadow bg-white hover:shadow-md m-2 rounded overflow-hidden border-2 border-transparent hover:border-2 hover:border-blue-500">
        {image && (
          <div
            data-testid="image"
            className="image w-full bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url("${image}")`,
            }}
          />
        )}
        <FlexCard
          left={
            <>
              <div data-testid="template" className="font-bold">
                {template}
              </div>
              <span data-testid="percent" className="text-sm text-gray-600">
                {percent}% of users
              </span>
            </>
          }
          right={
            <span data-testid="count" className="text-lg font-bold">
              {count}
            </span>
          }
        />
      </div>
    </HomepageCardContainer>
  );
};

const HomepageCardContainer = styled.div`
  position: relative;
  flex-basis: 20%;
  min-width: 250px;
  margin: 0 auto;
  .image {
    padding-top: 62.5%;
  }
`;
export default HomepageCard;

HomepageCard.propTypes = {
  template: PropTypes.any.isRequired,
  count: PropTypes.number.isRequired,
  image: PropTypes.string,
  percent: PropTypes.number.isRequired,
};

HomepageCard.defaultProps = {
  image: "",
};
