import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';

const DISABLE_SITE_MUTATION = gql`
	mutation($siteId: String!) {
		disableSite(siteId: $siteId)
	}
`;

export default function DisableSiteButton({ siteId }) {
	const [disable, { loading }] = useMutation(DISABLE_SITE_MUTATION, {
		variables: {
			siteId,
		},
	});

	const disableSite = async event => {
		event.preventDefault();
		await disable();
		toast.success('Successfully disabled');
	};

	return (
		<div>
			{loading ? (
				<span>Loading...</span>
			) : (
				<button className="button" type="button" onClick={disableSite}>
					Disable Site
				</button>
			)}
		</div>
	);
}
DisableSiteButton.propTypes = { siteId: PropTypes.string.isRequired };
