import React, { Component } from "react";
import axios from "axios";
import Loader from "./MainLoader";

export default class DeployLeadSites extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, errors: [], isSuccess: false };

    this.buildSite = this.buildSite.bind(this);
  }

  async buildSite(e) {
    e.preventDefault();

    this.setState({ isLoading: true });

    try {
      await axios.post(
        "https://w1g9f5qkfc.execute-api.us-east-1.amazonaws.com/dev/tag"
      );

      this.setState({ isSuccess: true });
    } catch (err) {
      this.setState({ errors: [err.message] });
    }

    this.setState({ isLoading: false });
  }

  render() {
    const { isLoading, errors, isSuccess } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (isSuccess) {
      return (
        <div className="bg-gray-100 h-screen">
          <div className="m-auto max-w-lg py-20">
            <h2 className="text-2xl text-gray-900 mb-2">
              Deploy build successful.
            </h2>
          </div>
        </div>
      );
    }

    return (
      <div className="bg-gray-100 h-screen">
        <form className="m-auto max-w-md py-20" onSubmit={this.buildSite}>
          <h2 className="text-2xl text-gray-900 mb-2">
            Let&apos;s deploy LeadSites
          </h2>

          <button className="block button" type="submit">
            Deploy
          </button>
        </form>
        {errors.length > 0 && (
          <ul>
            {errors.map((err) => (
              <li key={err}>{err}</li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}
