import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import Papa from "papaparse";
import downloadCsv from "../../helpers/downloadCsv";
import Loader from "../MainLoader";
import ReportContainer from "../Layout/ReportsContainer/ReportsContainer";


const GET_SITES_WITH_RETS = gql`
  {
    idxRoster {
      siteId
      retsBoards
      mappedDomain
      domain
      name
      company
      email
      mlsName
      agentID
      brokerName
      brokerEmail
      officeID
      isDisabled
    }
  }
`;

const head = [
    "mlsName",
    "name",
    "email",
    "company",
    "agentID",
    "brokerName",
    "brokerEmail",
    "officeID",
    "retsBoards",
    "mappedDomain",
    "domain",
];



export default function IdxRoster() {
    const { loading, data } = useQuery(GET_SITES_WITH_RETS);
    const sites = ((data && data.idxRoster) || []).map((site) => ({
        ...site,
        retsBoards: [...site?.retsBoards].filter((board) => board !== "CUSTOM"),
    }));

    const flatArrayOfBoardOptions = sites.map((site) => site.retsBoards).flat();
    const boards = Array.from(new Set(flatArrayOfBoardOptions));


    const getSitesFromBoardName = (boardName) =>
        sites.filter(
            (site) => !site.isDisabled && site.retsBoards.includes(boardName)
        );

    const countTotalBoards = (boards) =>{
        let total = 0;
        boards.map((board) => (
            total += getSitesFromBoardName(board).length
        ));
        return total;
    }
    const totalBoards = React.useMemo(() => countTotalBoards(boards), [boards]);

    const downloadAllRows = () => {
        const csvString = Papa.unparse({
            fields: head,
            data: sites
                ?.filter((site) => !site.isDisabled)
                .map((item) => head.map((h) => item[h])),
        });
        downloadCsv({ csvString, filename: `eap_rets_export` });
    };

    const downloadRowsAsCsv = (rows, boardName) => {
        const csvString = Papa.unparse({
            fields: head,
            data: rows.map((item) => head.map((h) => item[h])),
        });
        downloadCsv({ csvString, filename: `${boardName}_export` });
    };

    if (loading) return <Loader />;




    function UpperReport() {
        return (
            <div className="flex flex-wrap m-auto">
                <div className="w-full flex flex-wrap">
                    <div className="w-full md:w-1/2" />
                    <div className="w-full md:w-1/2 text-right">
                        <span className="text-gray-800 text-xl px-2">Total {totalBoards}</span>
                        
                        <button
                            onClick={downloadAllRows}
                            type="button"
                            className="button py-2 px-1"
                        >
                            Download All
                        </button>
                    </div>
                </div>
                {boards.map((board) => (
                    <div key={board} className="w-1/2 my-4">
                        <div className="bg-gray-200 shadow rounded mx-4 text-gray-800 py-4 px-2">
                            <div className="flex flex-wrap justify-between">
                                <h2 className="text-xl text-gray-900">{board} ({getSitesFromBoardName(board).length})</h2>
                                <button
                                    type="button"
                                    className="button py-2 px-1"
                                    onClick={() => {
                                        downloadRowsAsCsv(getSitesFromBoardName(board), board);
                                    }}
                                >
                                    Download Csv
                                </button>
                            </div>

                            <p className="text-gray-600 text-xs pb-2" />
                            <h3 className="text-gray-700 text-lg pb-2">Active roster:</h3>
                            <ul>
                                {getSitesFromBoardName(board).map((s) => (
                                    <li key={s.siteId + '-' + board + '-' + s.email} className="pb-0 mb-0">
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={`/accounts/profile#${s.siteId}`}
                                        >
                                            {s.mappedDomain || s.domain}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <ReportContainer
            title="IDX Roster"
            subtitle="Here is the current list of users with IDX Enabled by board."
            upper={<UpperReport />}
            lower={<div />}
        />
    );
}
