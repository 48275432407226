import isEmpty from "lodash.isempty";
import toUrl from "../../helpers/toUrl";
import toTime from "../../helpers/toTime";
import toMoney from "../../helpers/toMoney";

export default class Site {
  constructor(data) {
    this.data = data;
  }

get cancelReason() {
  return this.data.cancelReason
}

  get name() {
    return this.data.name;
  }

  get email() {
    return this.data.email;
  }

  get phone() {
    return this.data.phone;
  }

  get coupon() {
    return this.data.coupon;
  }

  get address() {
    return this.data.address;
  }

  get cityStateZip() {
    return this.data.cityStateZip;
  }

  get score() {
    return this.data.score;
  }

  get textyCodes() {
    return this.data.texty;
  }

  get delinquent() {
    return this.data.delinquent;
  }

  get created() {
    return toTime(this.data.created);
  }

  get latestPayment() {
    return toTime(this.data.latestPayment);
  }

  get monthlyAmount() {
    return toMoney(this.data.monthlyAmount);
  }

  get lifeTimeValue() {
    return toMoney(this.data.lifeTimeValue);
  }

  get domain() {
    return this.data.domain;
  }

  get lifeTimeValueNum() {
    return this.data.lifeTimeValue;
  }

  get siteUrl() {
    if (!isEmpty(this.data.mappedDomain)) {
      return toUrl(this.data.mappedDomain);
    }
    return toUrl(this.data.domain);
  }

  get squeezes() {
    return this.data.squeezes;
  }

  get vendors() {
    return this.data.vendors;
  }

  get ticketsLifeTime() {
    return this.data.ticketsLifeTime;
  }

  get uniqueTraffic() {
    return this.data.uniqueTraffic;
  }

  get posts() {
    return this.data.posts;
  }

  get popups() {
    return this.data.popups;
  }

  get leads() {
    return this.data.leads;
  }

  get leadsLast30() {
    return this.data.leadsLast30;
  }

  get leadsToTraffic() {
    return this.data.leadsToTraffic;
  }

  get webinar() {
    return this.data.hasAttendedWebinar;
  }

  get farms() {
    return this.data.farms;
  }

  get team() {
    return this.data.team;
  }

  get ihfActive() {
    return this.data.ihfActive ? "True" : "False";
  }

  get homesTemplate() {
    return this.data.homesTemplate;
  }

  get isArchived() {
    return !!this.data.isArchived;
  }

  get siteId() {
    return this.data.siteId;
  }

  get isDisabled() {
    return !!this.data.isDisabled;
  }

  get mappedDomain() {
    return this.data.mappedDomain;
  }

  get isDeleted() {
    return !!this.data.isDeleted;
  }

  get notifEmail() {
    return this.data.notifEmail;
  }

  get company() {
    return this.data.company;
  }

  get intercomEmail() {
    return this.data.intercomEmail;
  }

  get eapRetsActive() {
    return this.data.eapRetsActive ? "True" : "False";
  }

  get zenDeskLink() {
    const ID = this.data.zendesk_id;
    const formattedLink = `https://easyagentpro.zendesk.com/agent/users/${ID}/requested_tickets`;
    return ID !== undefined ? formattedLink : false;
  }

  get cloudflareLink() {
    const domain = this.data.mappedDomain;
    const formattedLink = `https://dash.cloudflare.com/d8f1ec0f1ee839b02bff2df2eae137f1/${domain}`;
    return domain !== undefined ? formattedLink : false;
  }

  get multiSite() {
    if (!isEmpty(this.data.multisite)) {
      return toUrl(this.data.multisite);
    }
    return "#";
  }

  get stripeId() {
    if (!isEmpty(this.data.stripe_id)) {
      return this.data.stripe_id;
    }
    return false;
  }

  get csm() {
    return this.data.csm;
  }

  get agileId() {
    return typeof this.data.agile_id !== 'undefined' ? this.data.agile_id : null;
  }

  get agileLink() {
    if(this.agileId !== null){
      return `https://easyagentp.agilecrm.com/#contact/${this.agileId}`;
    } else {
      return ``;
    }
  }

  get keapId() {
    return typeof this.data.keap_id !== 'undefined' ? this.data.keap_id : null;
  }

  get keapLink(){
    if(this.keapId !== null){
      return `https://hlp498.infusionsoft.com/Contact/manageContact.jsp?view=edit&ID=${this.keapId}`
    } else {
      return ``;
    }
  }

  get statsPercentage() {
    return this.data.statsPercentage;
  }

  get logo() {
    if (!isEmpty(this.data.logo)) {
      return this.data.logo;
    }
    return false;
  }

  get headshot() {
    return this.data.headshot;
  }

  get facebook() {
    return this.data.facebook;
  }

  get linkedin() {
    return this.data.linkedin;
  }

  get mlsName() {
    return this.data.mlsName;
  }

  get agentID() {
    return this.data.agentID;
  }

  get brokerName() {
    return this.data.brokerName;
  }

  get brokerEmail() {
    return this.data.brokerEmail;
  }

  get officeID() {
    return this.data.officeID;
  }
}
