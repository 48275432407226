import React, { Component } from "react";
import isEmpty from "lodash.isempty";
import ReactChartkick, { LineChart, PieChart } from "react-chartkick";
import Chart from "chart.js";
import CustomAxios from "../../data/axios";
import Loader from "../MainLoader";
import screenshots from "./homepageScreenshots";
import HomepageCard from "./HomepageCard/HomepageCard";
import whatPercent from "../../helpers/whatPercent";
import ReportContainer from "../Layout/ReportsContainer/ReportsContainer";

export default class HomePageReport extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      homepages: [],
    };
  }

  async componentDidMount() {
    try {
      const data = await CustomAxios.get("reports-homepages");
      if (!isEmpty(data.homepageCount)) {
        const homepages = data.homepageCount.map((home) => {
          const d = home;
          d.screenshot = screenshots(home.value);
          return d;
        });
        this.setState({
          isLoading: false,
          homepages,
          total: data.total,
        });
      }
    } catch (err) {
      console.warn({ err });
    }
  }

  render() {
    const { isLoading, homepages, total } = this.state;
    const pieChartData = homepages
      .filter((item) => item.count > 30)
      .map((item) => [
        `Template: ${item.value === "false" ? "Classic" : item.value}`,
        item.count,
      ]);
    ReactChartkick.addAdapter(Chart);

    const UpperContent = () => (
      <div className="flex flex-wrap justify-between">
        <div className="xs:w-full md:w-2/3 pr-6">
          <LineChart
            textStyle={{ color: "red" }}
            colors={["#4299e1", "#000", "#0000"]}
            data={homepages.map((home) => [
              `Template: ${home.value === "false" ? "Classic" : home.value}`,
              home.count,
            ])}
          />
        </div>
        <div className="xs:w-full md:w-1/3">
          <h4 className="mb-1">Most Popular Homepage</h4>
          <p className="text-sm text-gray-800 mb-4">
            Here are the most used Homepage Templates.
          </p>
          <PieChart data={pieChartData} donut />
        </div>
      </div>
    );

    const LowerContent = () => (
      <div className="flex flex-wrap">
        {homepages.map((home) => (
          <HomepageCard
            key={home.value}
            template={`Template: ${
              home.value === "false" ? "Classic" : Number(home.value)
            }`}
            count={Number(home.count)}
            image={home.screenshot}
            percent={whatPercent(home.count, total)}
          />
        ))}
      </div>
    );

    if (isLoading) {
      return <Loader />;
    }

    return (
      <ReportContainer
        title="What homepage is most popular?"
        subtitle="Here is the data we have collected for each homepage template."
        upper={<UpperContent />}
        lower={<LowerContent />}
      />
    );
  }
}
