const screenshots = (value) => {
  switch (value) {
    case "false":
      return "https://files.easyagentpro.com/homes/templates/0.png";
    case "0":
      return "https://files.easyagentpro.com/homes/templates/0.png";
    case "1":
      return "https://files.easyagentpro.com/homes/templates/1.png";
    case "2":
      return "https://files.easyagentpro.com/homes/templates/2.png";
    case "3":
      return "https://files.easyagentpro.com/homes/templates/3.png";
    case "4":
      return "https://files.easyagentpro.com/homes/templates/4.png";
    case "5":
      return "https://files.easyagentpro.com/homes/templates/5.png";
    case "6":
      return "https://files.easyagentpro.com/homes/templates/6.png";
    case "7":
      return "https://files.easyagentpro.com/homes/templates/7.png";
    case "8":
      return "https://files.easyagentpro.com/homes/templates/8.png";
    case "9":
      return "https://files.easyagentpro.com/homes/templates/screenshot.png";
    case "10":
      return "https://files.easyagentpro.com/HomePages/rochester+copy+3.png";
    case "11":
      return "https://files.easyagentpro.com/homes/templates/videoModern.png";
    case "12":
      return "https:///files.easyagentpro.com/HomePages/2019modern.png";
    default:
      return "";
  }
};

export default screenshots;
