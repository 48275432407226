import React from 'react';

const KeapId = (
    {ID}:{
        ID:number
    }) => {
 return (
    <>
        <a
            href={`https://hlp498.infusionsoft.com/Contact/manageContact.jsp?view=edit&ID=${ID}`}
            target="_blank"
            rel="noopener noreferrer"
        >
            {ID}
        </a>
    </>
 );
}

export default KeapId;
