const MinMaxFilter = (filter, row) => {
	const { min, max } = filter.value;

	if (min && max) {
		return row[filter.id] >= parseInt(min, 10) && row[filter.id] < parseInt(max, 10);
	}
	if (min && !max) {
		return row[filter.id] >= parseInt(min, 10);
	}

	return row[filter.id] <= parseInt(max, 10);
};

export default MinMaxFilter;
