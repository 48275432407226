import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const FlexCard = props => {
	const { left, right } = props;
	return (
		<FlexCardContainer>
			<div className="component__flexCard  flex items-center justify-between p-2 px-4 text-base hover:bg-blue-400 hover:border-blue-500 hover:text-white">
				<span data-testid="left" className="text-black-800">
					{left}
				</span>
				<span data-testid="right" className="text-base flex">
					{right}
				</span>
			</div>
		</FlexCardContainer>
	);
};

const FlexCardContainer = styled.div`
	position: relative;

	&:hover {
		span {
			color: white !important;
		}
		i {
			color: #fff !important;
		}
	}
`;
export default FlexCard;

FlexCard.propTypes = {
	left: PropTypes.node,
	right: PropTypes.node,
};

FlexCard.defaultProps = {
	left: '',
	right: '',
};
