import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const UserFunctionCard = props => {
	const { title, subtitle, button, onClick, isLoading, EntireButton } = props;
	return (
		<UserFunctionCardContainer>
			<div className="flex justify-between p-4 hover:bg-blue-100 rounded border border-transparent hover:border-gray-200 mb-2">
				{isLoading && (
					<div
						data-testid="isLoading"
						className=" w-8 h-8 flex spinner justify-center align-center text-center flex-col rounded-full border border-2 border-white"
					>
						<i style={{ fontSize: '24px' }} className="material-icons text-white">
							sync
						</i>
					</div>
				)}
				<div className="w-2/3">
					<h4 data-testid="title" className="text-base mb-2">
						{title}
					</h4>
					<p data-testid="subtitle" className="text-base text-gray-700">
						{subtitle}
					</p>
				</div>
				<div>
					{EntireButton ? (
						<EntireButton />
					) : (
						<button
							data-testid="button"
							onClick={onClick}
							type="button"
							className={isLoading ? 'button bg-blue-500 opacity-50 cursor-not-allowed ' : 'button'}
						>
							{button || 'Do it'}
						</button>
					)}
				</div>
			</div>
		</UserFunctionCardContainer>
	);
};

const UserFunctionCardContainer = styled.div`
	position: relative;
`;
export default UserFunctionCard;

UserFunctionCard.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	button: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	isLoading: PropTypes.bool,
	EntireButton: PropTypes.node,
};

UserFunctionCard.defaultProps = {
	isLoading: false,
	EntireButton: null,
};
