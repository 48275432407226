import moment from 'moment';

const fromRawTimeStamp = time => {
	if (!time) {
		return 'NA';
	}
	return moment(time).format('hh:mm:ss  MM Do, YYYY');
};

export default fromRawTimeStamp;
