import moment from "moment";

const toTime = (time) => {
  if (!time) {
    return "NA";
  }
  return moment.unix(time).format("MMM Do, YYYY");
};

export default toTime;
