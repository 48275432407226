const domainFilter = (filter, row) => {
	try {
		const rowPhone = row[filter.id]
			.replace(/https:\/\//, '')
			.replace(/http:\/\//, '')
			.toLowerCase();
		const value = filter.value
			.replace(/https:\/\//, '')
			.replace(/http:\/\//, '')
			.toLowerCase();
		return rowPhone.includes(value);
	} catch (err) {
		return false;
	}
};

export default domainFilter;
