
const toMoney = someNumber => {
	let num = someNumber

	if (typeof num === 'undefined') {
		num = 0
	}

	return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num / 100)
}

export default toMoney