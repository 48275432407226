import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import Loader from '../MainLoader';
import stringFilter, { exactMatchFilter } from '../Accounts/StringFilter';
import CustomAxios from '../../data/axios';
import fromRawTimeStamp from '../../helpers/fromRawTimeStamp';
import EventTag from './EventTag';

const getData = async () => {
	try {
		const res = await CustomAxios.get('event');
		const { events } = res;
		return events;
	} catch (err) {
		console.error({ err });
		return false;
	}
};
const ReactTableFixedColumns = withFixedColumns(ReactTable);
export default function EventPage() {
	const [isLoading, updateLoading] = useState(true);
	const [events, setData] = useState([]);

	const getInitialLoad = async () => {
		const rawEvents = await getData();
		setData(rawEvents);
		updateLoading(false);
	};

	const handleEventDisplay = eventName => {
		if (eventName === 'login') return <EventTag name="User logged in" icon="account_circle" />;
		if (eventName === 'saved-setting') return <EventTag name="Saved a setting" icon="settings" />;
		if (eventName === 'changed-template') return <EventTag name="Changed a template" icon="compare_arrows" />;
		if (eventName === 'visited-cancel-center') return <EventTag name="Visited cancel center" icon="cancel" danger />;
		if (eventName === 'purchased-product') return <EventTag name="Purchased a product" icon="payment" />;
		if (eventName === 'traffiky') return <EventTag name="Traffiky request for information" icon="monetization_on" />;

		return eventName;
	};

	useEffect(() => {
		getInitialLoad();
	}, []);

	if (isLoading) {
		return <Loader />;
	}

	if (!events) {
		return <div>error</div>;
	}

	return (
		<div className="p-4">
			<ReactTableFixedColumns
				className="-striped -highlight"
				data={events}
				filterable
				defaultSorted={[{ id: 'createdAt', desc: true }]}
				columns={[
					{
						Header: 'Events',
						columns: [
							{
								Header: 'Created At',
								Cell: ({ value }) => fromRawTimeStamp(value),
								accessor: act => act.createdAt,
								id: 'createdAt',
								width: 250,
								filterable: false,
							},
							{
								Header: 'Type',
								accessor: act => act.type,
								id: 'type',
								width: 250,
								filterMethod: (filter, rows) => stringFilter(filter, rows),
								Cell: ({ value }) => handleEventDisplay(value),
								filterAll: true,
							},
							{
								Header: 'Note',
								accessor: act => act.note,
								id: 'note',
								width: 600,
								filterMethod: (filter, rows) => stringFilter(filter, rows),
								filterAll: true,
							},

							{
								Header: 'Username',
								accessor: act => act.username,
								id: 'username',
								filterMethod: (filter, rows) => stringFilter(filter, rows),
								filterAll: true,
							},
							{
								Header: 'Site Url',
								accessor: act => act.siteUrl,
								id: 'siteUrl',
								filterMethod: (filter, rows) => exactMatchFilter(filter, rows),
								filterAll: true,
							},
							{
								Header: 'Site ID',
								accessor: act => act.siteId,
								id: 'siteId',
								filterMethod: (filter, rows) => stringFilter(filter, rows),
								filterAll: true,
							},
						],
					},
				]}
			/>
		</div>
	);
}
