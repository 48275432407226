import React, { Component } from "react";
import isEmpty from "lodash.isempty";
import "react-table/react-table.css";
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
// import { PieChart } from 'react-chartkick';
// import { Chart } from 'react-google-charts';
import CustomAxios from "../../data/axios";
import Loader from "../MainLoader";
import ReportContainer from "../Layout/ReportsContainer/ReportsContainer";
// import whatPercent from '../../helpers/whatPercent';
import toTime from "../../helpers/toTime";
import toMoney from "../../helpers/toMoney";
import toUrl from "../../helpers/toUrl";
import UserProfileIcon from "../Accounts/userProfileIcon";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export default class DelinquentReport extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      sites: [],
    };
  }

  async componentDidMount() {
    try {
      const data = await CustomAxios.get("sites", {});
      if (!isEmpty(data)) {
        const { sites } = data;
        this.setState({
          isLoading: false,
          sites,
        });
      }
    } catch (err) {
      console.warn({ err });
    }
  }

  render() {
    const { isLoading, sites } = this.state;
    if (isLoading) {
      return <Loader />;
    }

    const billingIsDelinquent = sites.filter(
      (site) => site.delinquent && site.stripe_id && site.monthlyAmount > 0
    );
    const reducer = (acc, cur) => acc + cur;
    const totalAmountMissing = billingIsDelinquent
      .map((site) => site.monthlyAmount)
      .reduce(reducer);
    const totalLifeTimeOfDelinquent = billingIsDelinquent
      .map((site) => site.lifeTimeValue)
      .reduce(reducer);
    const avgLifeTimeofDelinquent =
      totalLifeTimeOfDelinquent / totalAmountMissing;

    console.warn({ sites });

    const UpperContent = () => (
      <>
        <div className="flex justify-betwen flex-wrap">
          <div className="md:w-2/3 xs:w-full text-black">
            <h4 className="mt-4 inline-flex p-2 bg-blue-100 text-blue-500 rounded">
              {toMoney(totalAmountMissing)} in monthly recurring revenue is
              currently delinquent.
            </h4>
            <h4 className="mt-4 inline-flex p-2 bg-blue-100 text-blue-500 rounded">
              {avgLifeTimeofDelinquent.toFixed(2)} months is the average
              LifeTime of current delinquents.
            </h4>
          </div>
        </div>
      </>
    );

    const LowerContent = () => (
      <ReactTableFixedColumns
        data={billingIsDelinquent}
        columns={[
          {
            Header: "",
            accessor: (act) => act,
            Cell: ({ value }) => (
              <UserProfileIcon
                id={value.siteId}
                name={value.name}
                email={value.email}
                phone={value.phone}
                url={toUrl(value.domain)}
                img={value.headshot}
              />
            ),
            id: "profile",
            filterable: false,
            style: {
              overflow: "visible",
              padding: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
            width: 65,
          },
          {
            Header: "Name",
            id: "name",
            accessor: (data) => data.name,
          },
          {
            Header: "Email",
            id: "email",
            accessor: (data) => data.email,
          },
          {
            Header: "Phone",
            id: "phone",
            accessor: (data) => data.phone,
          },
          {
            Header: "Latest Payment",
            id: "latestPayment",
            accessor: (data) => data.latestPayment,
            Cell: ({ value }) => toTime(value),
          },
          {
            Header: "LifeTime Value",
            id: "lifetimeValue",
            accessor: (data) => data.lifeTimeValue,
            Cell: ({ value }) => toMoney(value),
          },
          {
            Header: "Monthly Amount",
            id: "monthlyAmount",
            accessor: (data) => data.monthlyAmount,
            Cell: ({ value }) => toMoney(value),
          },
          {
            Header: "Payment Status",
            id: "payment",
            accessor: (data) =>
              data.delinquent && (
                <span className="text-red-500">● Delinquent</span>
              ),
          },
        ]}
        defaultPageSize={25}
        className="-striped -highlight"
      />
    );

    return (
      <ReportContainer
        title="Users with Delinquent Status"
        subtitle="Here is the current list of users with Delinquent Status."
        upper={<UpperContent />}
        lower={<LowerContent />}
      />
    );
  }
}
