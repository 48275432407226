const UserIsActive = (filter, row) => {
	if (filter.value === 'all') {
		return true;
	}

	const { delinquent, monthlyAmount } = row._original; //eslint-disable-line

	if (filter.value === 'hold') return !delinquent && (monthlyAmount < 2600 && monthlyAmount > 0);
	if (filter.value === 'paid') return !delinquent && monthlyAmount > 0;
	if (filter.value === 'delinquent') return delinquent || monthlyAmount > 0;
	if (filter.value === 'cancelled') return delinquent || monthlyAmount === 0;

	return delinquent || monthlyAmount === 0;
};

export default UserIsActive;
