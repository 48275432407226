import React, { Component } from "react";
import axios from "axios";
import Loader from "../MainLoader";

export default class BuildPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            errors: [],
            stripeID: "",
            leadsiteExpiresAfterDate: "",
            checkbox: false,
            email: "",
            url: "",
            isSuccess: false,
            marketing: false,
        };

        this.buildSite = this.buildSite.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.changeCheck = this.changeCheck.bind(this);
    }

    handleChange(e) {
        const { value, name } = e.target;

        this.setState({ [name]: value });
    }

    changeCheck(e) {
        const { name, checked } = e.target;
        this.setState({ [name]: checked });
    }

    async buildSite(e) {
        e.preventDefault();

        const { email, checkbox, stripeID, leadsiteExpiresAfterDate, marketing } = this.state;
        let dateToUse = leadsiteExpiresAfterDate || null;

        if (leadsiteExpiresAfterDate) {
            const [yyyy, mm, dd] = leadsiteExpiresAfterDate?.split("-");
            if (!yyyy || !mm || !dd) {
                dateToUse = null;
            }
        }

        if (!checkbox || !stripeID || !email) {
            return;
        }

        this.setState({ isLoading: true });

        // try {
        //   axios.post("https://go.eapdev.com/add-contact-to-sqs", {
        //     email,
        //     tag: "brain_build",
        //     key: "jWQ2~8Wm$HM,DZ~J",
        //   });
        // } catch (error) {
        //   console.log(error);
        // }

        try {
            const res = await axios.post(
                `https://leads.leadsites.biz/api/support-account`,
                // `http://leadserver.local:8000/api/support-account`,
                {
                    key: "dkj34DJF***#$",
                    email,
                    id: stripeID,
                    leadsite_expires_after_date: dateToUse,
                    marketing: marketing,
                },
                {
                    timeout: 600000, // 10 minutes in milliseconds
                }
            );

            const { data } = res;
            const { url } = data;
            // axios.get(url);
            this.setState({ url, isSuccess: true });
            console.warn({ data });
        } catch (err) {
            this.setState({ errors: [err.message] });
        }

        this.setState({ isLoading: false });
    }

    render() {
        const {
            isLoading,
            stripeID,
            email,
            checkbox,
            errors,
            leadsiteExpiresAfterDate,
            isSuccess,
            url,
            marketing
        } = this.state;

        if (isLoading) {
            return <Loader />;
        }

        if (isSuccess) {
            return (
                <div className="bg-gray-100 h-screen">
                    <div className="m-auto max-w-lg py-20">
                        <h2 className="text-2xl text-gray-900 mb-2">Site build success.</h2>
                        <p className="mb-2">
                            Login url: <a href={url}>{url}</a>
                        </p>
                        <p className="text-xs">
                            The client was also emailed this data. You should also email them
                            the link just in case.
                        </p>

                        <button
                            onClick={() => window.location.reload()}
                            className="button --is-blue mt-16"
                            type="button"
                        >
                            Ready to build another?
                        </button>
                    </div>
                </div>
            );
        }

        return (
            <div className="bg-gray-100 h-screen">
                <form className="m-auto max-w-md py-20" onSubmit={this.buildSite}>
                    <h2 className="text-2xl text-gray-900 mb-2">
                        Let&apos;s build a new LeadSite
                    </h2>
                    <p className="mb-8">
                        Enter the user StripeID and email below to deploy a new site.
                    </p>
                    <label className="block label font-bold mb-2" htmlFor="stripeID">
                        Stripe ID
                    </label>
                    <input
                        placeholder="ie. cus_Bil99TTsjR45Ap"
                        className="block input w-full border mb-6 px-4 py-2 rounded focus:shadow focus:border-blue-500"
                        type="text"
                        id="stripeID"
                        name="stripeID"
                        value={stripeID}
                        onChange={this.handleChange}
                    />
                    <label className="block font-bold mb-2" htmlFor="email">
                        Email
                    </label>
                    <input
                        placeholder="name@example.com"
                        className="block input w-full border mb-6 px-4 py-2 rounded focus:shadow focus:border-blue-500"
                        type="text"
                        id="email"
                        name="email"
                        value={email}
                        onChange={this.handleChange}
                    />
                    <label
                        className="block font-bold mb-2"
                        htmlFor="leadsiteExpiresAfterDate"
                    >
                        Expire after
                    </label>
                    <input
                        placeholder="name@example.com"
                        className="block input w-full border mb-6 px-4 py-2 rounded focus:shadow focus:border-blue-500"
                        type="date"
                        id="leadsiteExpiresAfterDate"
                        name="leadsiteExpiresAfterDate"
                        value={leadsiteExpiresAfterDate}
                        onChange={this.handleChange}
                    />
                    <div className="mb-6">
                        <input
                            className="mr-2"
                            type="checkbox"
                            id="checkbox"
                            name="checkbox"
                            value={checkbox}
                            onChange={this.changeCheck}
                        />
                        <label className="font-bold" htmlFor="checkbox">
                            Yes, I want to build a new LeadSite.
                        </label>
                    </div>
                    <div className="mb-6">
                        <input
                            className="mr-2"
                            type="checkbox"
                            id="marketing"
                            name="marketing"
                            value={marketing}
                            onChange={this.changeCheck}
                        />
                        <label className="font-bold" htmlFor="marketing">
                            User has consented to receive marketing.
                        </label>
                    </div>
                    <button className="block button" type="submit">
                        Build Site
                    </button>
                </form>
                {errors.length > 0 && (
                    <ul>
                        {errors.map((err) => (
                            <li key={err}>{err}</li>
                        ))}
                    </ul>
                )}
            </div>
        );
    }
}
