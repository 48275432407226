import React from "react";
// import PropTypes, { string } from "prop-types";
import "./PercentagePicker.scss";

export default function PercentagePicker({
    value,
    changeSelect,
}: {
    value: number | string;
    changeSelect(): any;
}) {
    return (
        <div>
            <label htmlFor="customersByPerc">Customers</label>
            <select id="customersByPerc" value={value} onChange={changeSelect}>
                <option value="all">All</option>
                <option value="top20">Top 20%</option>
                <option value="mid75">Mid 75%</option>
                <option value="bot5">Bottom 5%</option>
            </select>
        </div>
    );
}

// PercentagePicker.propTypes = {
//     value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//     changeSelect: PropTypes.func.isRequired,
// };
