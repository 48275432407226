import React, { Component } from 'react';
import isEmpty from 'lodash.isempty';
import ReactChartkick, { LineChart } from 'react-chartkick';
import Chart from 'chart.js';
import CustomAxios from '../../../data/axios';
import Loader from '../../MainLoader';
import HomepageCard from '../HomepageCard/HomepageCard';
import whatPercent from '../../../helpers/whatPercent';
import ReportContainer from '../../Layout/ReportsContainer/ReportsContainer';

export default class CrmReport extends Component {
	constructor() {
		super();
		this.state = {
			isLoading: true,
			crms: []
		};
	}

	async componentDidMount() {
		try {
			const data = await CustomAxios.get('reports-crms');
			if (!isEmpty(data.crmCount)) {
				const crms = data.crmCount.map(home => {
					const d = home;
					// d.screenshot = screenshots(home.value);
					return d;
				});
				this.setState({
					isLoading: false,
					crms,
					total: data.total
				});
			}
		} catch (err) {
			console.warn({ err });
		}
	}

	render() {
		const { isLoading, crms, total } = this.state;
		ReactChartkick.addAdapter(Chart);

		const UpperContent = () => (
			<LineChart
				textStyle={{ color: 'red' }}
				colors={['#4299e1', '#000', '#0000']}
				data={crms.map(home => [home.value, home.count])}
			/>
		);

		const LowerContent = () => {
			return crms.map(crm => (
				<HomepageCard
					key={crm.value}
					template={crm.value}
					count={Number(crm.count)}
					// image={home.screenshot}
					percent={whatPercent(crm.count, total)}
				/>
			));
		};

		if (isLoading) {
			return <Loader />;
		}

		return (
			<ReportContainer
				title="What CRM is most popular?"
				subtitle="Here is the data we have collected for each crm."
				upper={<UpperContent />}
				lower={<LowerContent />}
			/>
		);
	}
}
