import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Papa from 'papaparse';
import downloadCsv from '../../helpers/downloadCsv';
import toMoney from '../../helpers/toMoney';
import toTime from '../../helpers/toTime';
import eapAccountRep from '../../helpers/eapAccountRep';

export default class ExportCsv extends Component {
	static propTypes = {
		getStuffFromTable: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
		};

		this.exportAsCsv = this.exportAsCsv.bind(this);
	}

	exportAsCsv(e) {
		e.preventDefault();
		this.setState({ isLoading: true });

		const blacklist = ['_original', '_index', '_subRows', '_nestingLevel', 'profile', 'cloudflare_id'];

		const { getStuffFromTable } = this.props;
		const data = getStuffFromTable();
		const [firstRow] = data;

		const head = ['firstName', 'lastName', ...Object.keys(firstRow).filter(item => !blacklist.includes(item))];

		const body = data.map(item =>
			head.map(h => {
				const value = item[h];

				switch (h) {
					case 'firstName':
						return (item.name || '').split(' ')[0];
					case 'lastName':
						return (item.name || '').split(' ')[1];
					case 'ltf':
					case 'monthly':
						return toMoney(value);
					case 'accountManager':
						return eapAccountRep[value.state];
					case 'last_pmt':
					case 'signed_up':
					case 'latestPayment':
					case 'created':
						return toTime(value);
					default:
						return value;
				}
			})
		);
		console.warn({ body });

		const csvString = Papa.unparse({ fields: head, data: body });
		downloadCsv({ csvString, filename: 'accounts_export' });
		this.setState({ isLoading: false });
	}

	render() {
		const { className } = this.props;
		const { isLoading } = this.state;
		return (
			<button name="exportToCsv" type="button" onClick={this.exportAsCsv} className={`button cursor-pointer ${className}`}>
				{isLoading ? (
					'loading'
				) : (
					<>
						<i className="material-icons">cloud_download</i> Export To CSV
					</>
				)}
			</button>
		);
	}
}
