import moment from 'moment';

const InputFilterLogic = (filter, row) => {
	const ifNeverLoggedIn = !row[filter.id];
	if (ifNeverLoggedIn) return true;

	const numberOfDays = parseInt(filter.value, 10);
	const numberOfDaysSinceLastLogin = moment().diff(row[filter.id], 'days');

	if (numberOfDays && numberOfDaysSinceLastLogin) {
		return numberOfDaysSinceLastLogin >= numberOfDays;
	}

	return false;
};

export default InputFilterLogic;
