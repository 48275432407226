import React from 'react'
import PropTypes from 'prop-types'


export default function ZendeskId(props) {
	const { ID } = props;
	return (
		<a href={`https://easyagentpro.zendesk.com/agent/users/${ID}/requested_tickets`} target="_blank" rel="noopener noreferrer">
			Zendesk
		</a>
	)
}

ZendeskId.propTypes = {
	ID: PropTypes.number.isRequired
}