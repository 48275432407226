export default site => {
	if (site.delinquent) {
		return false;
	}

	if (site.monthlyAmount === 0) {
		return false;
	}

	return true;
};
